/*eslint-disable */

// @flow

// Libraries
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';

// Components
import {
  Button,
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table as MaterialTable
} from '@material-ui/core';
import Table from '@components/Table';
import Page from '@components/Page';
import ChartControlHeader from '@components/ChartControlHeader';
import Loader from '@components/Loader';
import Chart from './components/Chart';
import Stats from './components/Stats';

// Methods
import { divideGraphsValue } from '../../../utils/methods';

// Constants
import { DEFAULT_SETTINGS } from '@constants/charts';
import { OPERATORS } from '@constants/general';

// Api
import {
  fetchCharts,
  getOneHistory,
  getYearAudienceChart,
  getXLCharts
} from '../../../api/chartsApi';

// Styles
import styles from './styles.sass';
import _ from 'lodash';
import { sortByDates } from './components/DynamicRowChartWithZoom';
import { CHART_VARIANTS } from './constants';

import PRValueTab from './tabs/PRValue';
import TabMood from './tabs/Mood';
import TabMagistralThemes from './tabs/MagistralThemes';
import useAuth from "../../../hooks/useAuth";


const CHARTS = {
  mentions_chart: 'mentions_chart',
  audience_chart: 'audience_chart',
  mentions_stats_year: 'mentions_stats_year',
  audience_stats_year: 'audience_stats_year',
  mentions_stats_period: 'mentions_stats_period',
  audience_stats_period: 'audience_stats_period',
  top_infopovodov: 'top_infopovodov',
  top3_pr_campaigns: 'top3_pr_campaigns',
  infopovodov_chart: 'infopovodov_chart',
  pr_campaigns: 'pr_campaigns',
  mood_chart: 'mood_chart',
  mood_stats: 'mood_stats',
  magistral_themes: 'magistral_themes',
  paid_free_chart: 'paid_free_chart',
  paid_free_table: 'paid_free_table',
  pr_campaigns_avg_weekly_stats_federal:
    'pr_campaigns_avg_weekly_stats_federal',
  pr_campaigns_avg_weekly_stats_regional:
    'pr_campaigns_avg_weekly_stats_regional'
};

const TABS_NAMES = {
  mentions_and_audience: 'mentions_and_audience',
  top_infopovodov: 'top_infopovodov',
  pr_campaigns_federal: 'pr_campaigns_federal',
  pr_campaigns_regional: 'pr_campaigns_regional',
  magistral_themes: 'magistral_themes',
  mood: 'mood',
  dev: 'dev',
  pr_value: 'pr_value',
}

const TABS = {
  [TABS_NAMES.mentions_and_audience]: {
    id: TABS_NAMES.mentions_and_audience,
    name: 'Упоминания и аудитория',
    charts: [
      CHARTS.mentions_chart,
      CHARTS.audience_chart,
      CHARTS.mentions_stats_period,
      CHARTS.mentions_stats_year,
      CHARTS.audience_stats_period,
      CHARTS.audience_stats_year
    ]
  },
  [TABS_NAMES.top_infopovodov]: {
    id: TABS_NAMES.top_infopovodov,
    name: 'Топ инфоповодов',
    charts: [CHARTS.top_infopovodov, CHARTS.infopovodov_chart]
  },
  [TABS_NAMES.pr_campaigns_federal]: {
    id: TABS_NAMES.pr_campaigns_federal,
    name: 'PR-кампании (Федеральные)',
    charts: [
      CHARTS.pr_campaigns,
      CHARTS.paid_free_chart,
      CHARTS.top3_pr_campaigns,
      CHARTS.paid_free_table,
      CHARTS.pr_campaigns_avg_weekly_stats_federal
    ]
  },
  [TABS_NAMES.pr_campaigns_regional]: {
    id: TABS_NAMES.pr_campaigns_regional,
    name: 'PR-кампании (Региональные)',
    charts: [
      CHARTS.pr_campaigns,
      CHARTS.paid_free_chart,
      CHARTS.top3_pr_campaigns,
      CHARTS.paid_free_table,
      CHARTS.pr_campaigns_avg_weekly_stats_regional
    ]
  },
  [TABS_NAMES.magistral_themes]: {
    id: TABS_NAMES.magistral_themes,
    name: 'Направления бизнеса',
    charts: [CHARTS.magistral_themes]
  },
  [TABS_NAMES.mood]: {
    id: TABS_NAMES.mood,
    name: 'Тональность',
    charts: [CHARTS.mood_chart, CHARTS.mood_stats]
  },
  [TABS_NAMES.dev]: {
    id: TABS_NAMES.dev,
    name: 'Тестовые графики',
    charts: [],
    isDev: true,
  },
  [TABS_NAMES.pr_value]: {
    id: TABS_NAMES.pr_value,
    name: "PR-value",
    charts: []
  },
};

export const DEFAULT_OPERATORS_QUEUE = [
  {
    id: 25, // здесь все наоборот - id и key поменяны местами. Надо бэк допиливать. чтобы сделать как в редакторе
    key: 'megafon',
    name: 'МегаФон',
    color: 'rgb(0, 185, 86)'
  },
  {
    id: 26,
    key: 'yota',
    name: 'YOTA',
    color: 'rgb(2, 185, 255)'
  },
  {
    id: 27,
    key: 'mts',
    name: 'МТС',
    color: 'rgb(235, 90, 64)'
  },
  {
    id: 28,
    key: 'beeline',
    name: 'Билайн',
    color: 'rgb(255, 167, 23)'
  },
  {
    id: 29,
    key: 'tele2',
    name: 'Теле2',
    color: 'rgb(68, 65, 137)'
  }
];

// переместить куда-нибудь
export const BRANCHES_QUEUE = [
  'Столичный',
  'Северо-Западный макрорегион',
  'Дальневосточный макрорегион',
  'Сибирский макрорегион',
  'Уральский макрорегион',
  'Поволжский макрорегион',
  'Кавказский макрорегион'
];

export const sortByBranchesQueue = arr =>
  arr.sort(
    (a, b) =>
      BRANCHES_QUEUE.findIndex(name => name === a.name) -
      BRANCHES_QUEUE.findIndex(name => name === b.name)
  );

const OPERATOR_BIG4 = {
  key: 'big4',
  name: 'Общие',
  color: 'gray'
};

export const ChartsContext = React.createContext(null);

const Charts = () => {
  const location = useLocation();
  const history = useHistory();
  const { monitoring_regions: regions } = useSelector(
    store => store.monitoring_constants
  );

  // only for dev
  const DEV_MODE = location.search.includes('DEV_MODE');

  // проверяем перешли ли мы по ссылке
  const [loading, setLoading] = React.useState(false);
  const [settings, setSettings] = React.useState(null);
  const [fetchedCharts, setFetchedCharts] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(TABS.mentions_and_audience);
  const [reportId, setReportId] = React.useState(
    location.pathname.split('/')[2]
  );

  const range = settings?.dateFrom &&
    settings?.dateTo && {
      startValue: moment(settings.dateFrom, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      endValue: moment(settings.dateTo, 'YYYY-MM-DD').format('DD.MM.YYYY')
    };

  const getSettings = async () => {
    if (!reportId) {
      setSettings(DEFAULT_SETTINGS);
    } else {
      await getOneHistory(reportId, res =>
        setSettings({
          ...res.data.params,
          operators: DEFAULT_SETTINGS.operators
        })
      );
    }
  };

  const OPERATORS_QUEUE = React.useMemo(
    () =>
      settings
        ? DEFAULT_OPERATORS_QUEUE.filter(({ key }) =>
            settings.operators.find(({ value }) => key === value)
          )
        : [],
    [settings]
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  const fetchAllCharts = async () => {
    if (settings) {
      setLoading(true);
      await fetchCharts(settings, (response, chart) => {
        setFetchedCharts(prev => ({ ...prev, [chart]: response }));
        setLoading(false);
      });
    }
  };

  React.useEffect(() => {
    getSettings();
  }, []);

  React.useEffect(() => {
    if (reportId && !fetchedCharts) fetchAllCharts();
  }, [settings]);

  const getDate = date => moment(date, 'YYYY-MM-DD');

  const prCampaignTableHeader = React.useMemo(() => ({
    cells: [
      {
        id: 'main',
        label: ``
      },
      {
        id: 'megafon',
        label: 'МегаФон'
      },
      {
        id: 'mts',
        label: 'МТС'
      },
      {
        id: 'beeline',
        label: 'билайн'
      },
      {
        id: 'tele2',
        label: 'Теле2'
      },
      {
        id: 'yota',
        label: 'YOTA'
      }
    ]
  }));

  const prCampaignTableData = React.useMemo(() => {
    const MONITORING_TYPES = ['federal', 'regional'];

    return [...MONITORING_TYPES, 'all'].reduce((reducer, type) => {
      const isAllType = type === 'all';
      const mainBranchOfType = isAllType
        ? fetchedCharts?.pr_campaigns?.data
        : fetchedCharts?.pr_campaigns?.data[type];

      const checkIsNotEmpty =
        fetchedCharts &&
        fetchedCharts?.pr_campaigns &&
        [
          mainBranchOfType.audience_stats,
          mainBranchOfType.campaigns_stats,
          mainBranchOfType.posts_stats
        ].length > 0;

      const content = checkIsNotEmpty
        ? [
            {
              id: 1,
              content: [
                {
                  id: 'main',
                  label: 'Всего PR кампаний'
                },
                ...mainBranchOfType.campaigns_stats.map(data => ({
                  id: data.operator_name,
                  label: divideGraphsValue(data.count)
                }))
              ]
            },
            {
              id: 2,
              content: [
                {
                  id: 'main',
                  label: 'Публикаций'
                },
                ...mainBranchOfType.posts_stats.map(data => ({
                  id: data.operator_name,
                  label: divideGraphsValue(data.count)
                }))
              ]
            },
            {
              id: 3,
              content: [
                {
                  id: 'main',
                  label: 'Аудитория'
                },
                ...mainBranchOfType.audience_stats.map(data => ({
                  id: data.operator_name,
                  label: divideGraphsValue(data.count, true)
                }))
              ]
            }
          ]
        : [];

      return {
        ...reducer,
        [type]: content
      };
    }, {});
  });

  const getYearRowChartData = React.useCallback(
    (chartName, chartData = null) => {
      if (fetchedCharts && (fetchedCharts[chartName] || chartData)) {
        const usedData = fetchedCharts[chartName] || chartData;

        if (usedData?.data && Array.isArray(usedData?.data)) {
          return usedData.data
            .sort(
              (a, b) =>
                new Date(getDate(a.label).format('YYYY/MM/DD')) -
                new Date(getDate(b.label).format('YYYY/MM/DD'))
            )
            .map(col => ({
              date: getDate(col.label).format('DD.MM.YYYY'),
              ...col.values
            }));
        }
      }

      return [];
    },
    [fetchedCharts]
  );

  const yearAudienceChartData = React.useMemo(() => {
    if (fetchedCharts && fetchedCharts?.audience_chart) {
      const usedData = fetchedCharts.audience_chart;
      const keys = sortByDates(
        Object.keys(usedData.data).map(date => ({ date }))
      ).map(({ date }) => date);
      const startOf = keys[0].split('-')[0];
      const endOfValues = keys[keys.length - 1].split('-');
      const endOf = endOfValues.length === 2 ? endOfValues[1] : endOfValues[0];

      return {
        data: usedData?.data,
        range: {
          startOf,
          endOf
        }
      };
    }

    return null;
  }, [fetchedCharts?.audience_chart]);

  const top_infopovodov_chart_data = React.useMemo(() => {
    return (
      fetchedCharts?.top_infopovodov &&
      fetchedCharts.top_infopovodov.data
        .sort((a, b) => a.count - b.count)
        .map(infopovod => ({
          label: infopovod.title,
          [infopovod.company]: infopovod.count
        }))
    );
  }, [fetchedCharts?.top_infopovodov]);

  const mood_stats_chart_data = React.useMemo(() => {
    return (
      fetchedCharts?.mood_stats &&
      _.orderBy(
        fetchedCharts.mood_stats.data.values.reduce(
          (reducer, { company, count, mood }) => {
            const foundIndex = reducer.findIndex(
              item => item.company === company
            );
            if (
              foundIndex > -1 &&
              settings.operators.find(({ value }) => value === company)
            ) {
              const updatedReducer = [...reducer];
              updatedReducer[foundIndex] = {
                ...reducer[foundIndex],
                [mood]: count
              };

              return updatedReducer;
            }
            return reducer;
          },
          _.uniqBy(fetchedCharts.mood_stats.data.values, 'company')
            .filter(({ company }) =>
              settings.operators.find(({ value }) => value === company)
            )
            .map(({ company }) => ({
              company,
              name: settings.operators.find(({ value }) => value === company)
                .label
            }))
        ),
        item =>
          DEFAULT_OPERATORS_QUEUE.map(({ name }) => name).indexOf(item.name),
        'asc'
      ).map(({ positive, negative, neutral, company, name }) => {
        const sumOfMood = (positive + negative + neutral) / 100;

        return {
          label: name,
          company,
          positive: positive / sumOfMood,
          negative: negative / sumOfMood,
          neutral: neutral / sumOfMood
        };
      })
    );
  }, [fetchedCharts?.mood_stats]);

  const mood_chart_data = React.useMemo(() => {
    return (
      fetchedCharts?.mood_chart && {
        data: fetchedCharts.mood_chart.data.map(col => ({
          label: getDate(col.label).format('YYYY-MM-DD'),
          values: col.values.reduce(
            (reducer, { company, count }) => ({
              ...reducer,
              [company]: count
            }),
            {}
          )
        }))
      }
    );
  }, [fetchedCharts?.mood_chart]);

  const paid_free_chart_data = React.useMemo(() => {
    return fetchedCharts?.paid_free_chart && fetchedCharts.paid_free_chart.data;
  }, [fetchedCharts?.paid_free_chart]);

  const infopovodov_stats = React.useMemo(() => {
    if (fetchedCharts?.infopovodov_chart?.data) {
      return fetchedCharts?.infopovodov_chart?.data?.slice(-7).map(item => ({
        ...item,
        date: moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
      }));
    }

    return [];
  }, [fetchedCharts?.infopovodov_chart]);

  const Tab = React.useCallback(
    data =>
      !loading &&
      fetchedCharts &&
      activeTab.id === data?.tab && (
        <div className={styles.Container}>{data?.children}</div>
      )
  );

  const PRCampaingsTable = ({ data: propsData }) => {
    if (propsData) {
      const {
        infopovod_name,
        magistral_theme,
        stats: notPreparedStats
      } = propsData;

      const stats = _.orderBy(
        notPreparedStats.filter(({ total }) => total.posts > 0),
        item => BRANCHES_QUEUE.map(name => name).indexOf(item.label),
        'asc'
      );

      const getSum = (field1, field2) =>
        stats.reduce((sum, stat) => sum + stat[field1][field2], 0);

      const mergedStats =
        stats.length > 1
          ? [
              ...stats,
              {
                name: 'Итого',
                total: {
                  audience: getSum('total', 'audience'),
                  posts: getSum('total', 'posts')
                },
                paid: {
                  audience: getSum('paid', 'audience'),
                  posts: getSum('paid', 'posts')
                },
                free: {
                  audience: getSum('free', 'audience'),
                  posts: getSum('free', 'posts')
                }
              }
            ]
          : stats;

      const HEADER_ROW_SPAN =
        stats.length > 1 ? stats.length + 2 : stats.length + 1;

      const getFormattedAudience = (posts, audience) => {
        if (posts === 0) {
          return 0;
        }

        return divideGraphsValue(audience, true);
      };

      return (
        <TableContainer>
          <MaterialTable className={styles.PRTable} size="medium" theme="black">
            <TableHead>
              <TableRow>
                <TableCell rowSpan={HEADER_ROW_SPAN} align="center">
                  PR-кампания
                </TableCell>
                <TableCell rowSpan={HEADER_ROW_SPAN} align="center">
                  Направление бизнеса
                </TableCell>
                <TableCell rowSpan={HEADER_ROW_SPAN} align="center">
                  Макрорегион
                </TableCell>
                <TableCell colSpan={2} align="center">
                  Всего
                </TableCell>
                <TableCell colSpan={2} align="center">
                  Неорганические
                </TableCell>
                <TableCell colSpan={2} align="center">
                  Органические
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Публикации</TableCell>
                <TableCell align="center">Аудитория (млн)</TableCell>
                <TableCell align="center">Публикации</TableCell>
                <TableCell align="center">Аудитория (млн)</TableCell>
                <TableCell align="center">Публикации</TableCell>
                <TableCell align="center">Аудитория (млн)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell rowSpan={HEADER_ROW_SPAN}>
                  {infopovod_name}
                </TableCell>
                <TableCell rowSpan={HEADER_ROW_SPAN} align="center">
                  {magistral_theme || 'Без направления бизнеса'}
                </TableCell>
              </TableRow>
              {mergedStats.map(({ free, paid, total, name: branchName }, i) => {
                const withBoldCells =
                  i === mergedStats.length - 1 && mergedStats.length > 1;
                const boldCellStyle = {
                  fontWeight: withBoldCells ? 'bold' : 'normal'
                };

                return (
                  <TableRow>
                    <TableCell align="center" style={boldCellStyle}>
                      {branchName}
                    </TableCell>
                    <TableCell align="center" style={boldCellStyle}>
                      {total.posts}
                    </TableCell>
                    <TableCell align="center" style={boldCellStyle}>
                      {getFormattedAudience(total.posts, total.audience)}
                    </TableCell>
                    <TableCell align="center" style={boldCellStyle}>
                      {paid.posts}
                    </TableCell>
                    <TableCell align="center" style={boldCellStyle}>
                      {getFormattedAudience(paid.posts, paid.audience)}
                    </TableCell>
                    <TableCell align="center" style={boldCellStyle}>
                      {free.posts}
                    </TableCell>
                    <TableCell align="center" style={boldCellStyle}>
                      {getFormattedAudience(free.posts, free.audience)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
      );
    }

    return <></>;
  };

  const PRCampaigns = ({ type }) => {
    const series = [
      {
        key: 'Неорганические',
        name: 'Неорганические'
      },
      {
        key: 'Органические',
        name: 'Органические'
      }
    ];

    const getDataSource = (title, { paid, free }, onlyMillions) => ({
      title,
      values: [
        {
          type: 'Неорганические',
          value: paid
        },
        {
          type: 'Органические',
          value: free
        }
      ],
      argumentField: 'type',
      valueField: 'value',
      palette: ['#00a84e', 'rgba(0,168,78, 0.6)'],
      customizeText: text =>
        `${divideGraphsValue(text.value, onlyMillions)} (${text.percentText})`
    });

    const style = {
      root: {
        display: 'flex',
        justifyContent: 'center'
      }
    };

    const tableData = fetchedCharts.paid_free_table?.data
      ? fetchedCharts.paid_free_table.data[type]
      : [];

    return paid_free_chart_data ? (
      paid_free_chart_data[type].length === 0 ? (
        <h2 style={{ textAlign: 'center', marginTop: 100, marginBottom: 100 }}>
          Данных по платным/бесплатным публикациям не найдено
        </h2>
      ) : (
        paid_free_chart_data[type].map(
          ({ audience, pr_company, published }) => (
            <>
              <div className={styles.Charts__Title}>{pr_company}</div>
              <div style={{ display: 'flex' }}>
                <Chart
                  type="PieChart"
                  dataSource={getDataSource('Публикации', published)}
                  series={series}
                  style={style}
                />
                <Chart
                  type="PieChart"
                  dataSource={getDataSource('Аудитория', audience, true)}
                  series={series}
                  style={style}
                />
              </div>
              <PRCampaingsTable
                data={tableData.find(
                  ({ infopovod_name }) => infopovod_name === pr_company
                )}
              />
            </>
          )
        )
      )
    ) : (
      <div className={styles.Progress}>
        <CircularProgress color="secondary" />
      </div>
    );
  };

  const TabsControl = () =>
    fetchedCharts && (
      <div className={styles.TabsControl}>
        <div className={styles.TabsControl__Tabs}>
          <div className={styles.TabsControl__Container}>
            {Object.entries(TABS).map(
              ([key, values]) => {
                if (values.name === 'PR-value') {
                  if (role === 'pr_manager') {
                    return ((!DEV_MODE && !values?.isDev) || DEV_MODE) && (
                      <div
                        key={key}
                        className={`${styles.TabsControl__Tab} ${key === activeTab.id ? styles.TabsControl__Tab_active : ""}`}
                        onClick={() => setActiveTab(values)}
                      >
                        {values.name}
                      </div>
                    )
                  }
                } else {
                  return ((!DEV_MODE && !values?.isDev) || DEV_MODE) && (
                    <div
                      key={key}
                      className={`${styles.TabsControl__Tab} ${key === activeTab.id ? styles.TabsControl__Tab_active : ""}`}
                      onClick={() => setActiveTab(values)}
                    >
                      {values.name}
                    </div>
                  )
                }
              }
            )}
          </div>
        </div>
        <div className={styles.TabsControl__Info}>
          <span>
            Показаны графики за период с{' '}
            <span className={styles.TabsControl__InfoText}>
              {moment(settings.dateFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
            </span>{' '}
            по{' '}
            <span className={styles.TabsControl__InfoText}>
              {moment(settings.dateTo, 'YYYY-MM-DD').format('DD.MM.YYYY')}
            </span>
          </span>
          <span className={styles.TabsControl__Divider}>|</span>
          <span>
            Регион(ы):
            <span className={styles.TabsControl__InfoText}>
              {' '}
              {settings.regions.length > 0
                ? settings.regions.length === DEFAULT_SETTINGS.regions.length
                  ? 'Все'
                  : settings.regions.map(branch => branch.label).join(', ')
                : 'Никакие'}
            </span>
          </span>
          {settings?.selectedRegions?.length !== 0 && (
            <>
              <span className={styles.TabsControl__Divider}>|</span>
              <span>
                Области:
                <span className={styles.TabsControl__InfoText}>
                  {' '}
                  {settings?.selectedRegions
                    ?.slice(0, 3)
                    ?.map(branch => branch.label)
                    .join(', ')}
                </span>
                {settings?.selectedRegions?.length > 3 && <>...</>}
              </span>
            </>
          )}
        </div>
      </div>
    );

  const formattedDateFrom =
    settings?.dateFrom &&
    moment(settings.dateFrom, 'YYYY-MM-DD').format('DD.MM.YYYY');
  const formattedDateTo =
    settings?.dateTo &&
    moment(settings.dateTo, 'YYYY-MM-DD').format('DD.MM.YYYY');

  const fetchYearAudienceChart = async ({ startValue, endValue }) => {
    return await getYearAudienceChart({
      ...settings,
      dateFrom: moment(startValue, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      dateTo: moment(endValue, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      offStatic: true
    }).then(res => (res ? res : { data: null }));
  };

  const TabMentionsAndAudience = () => (
    <Tab tab={TABS_NAMES.mentions_and_audience}>
      <Chart
        type="RowChartWithZoom"
        variant={CHART_VARIANTS.average}
        range={range}
        dataSource={getYearRowChartData('mentions_chart')}
        series={OPERATORS_QUEUE}
        title="Количество упоминаний за год"
        tooltipText={{
          days: 'Упоминания за',
          average: 'Среднее кол-во упоминаний за'
        }}
      />
      <Stats
        data={{
          year: fetchedCharts.mentions_stats_year.data,
          period: fetchedCharts.mentions_stats_period.data
        }}
        titles={{
          year: {
            all: `Количество упоминаний с 01.01 по ${formattedDateTo}`,
            types: `Количество упоминаний с 01.01 по ${formattedDateTo} (федеральные/региональные)`,
            regions: `Количество упоминаний с 01.01 по ${formattedDateTo} (макрорегионы)`
          },
          period: {
            all: `Количество упоминаний за период с ${formattedDateFrom} по ${formattedDateTo} `,
            types: `Количество упоминаний за период с ${formattedDateFrom} по ${formattedDateTo} (федеральные/региональные)`,
            regions: `Количество упоминаний за период с ${formattedDateFrom} по ${formattedDateTo} (макрорегионы)`
          }
        }}
        compact
        outerData={{
          regions,
          OPERATORS_QUEUE
        }}
      />
      <Chart
        type="DynamicRowChartWithZoom"
        range={range}
        dataSource={yearAudienceChartData}
        onChangeRange={fetchYearAudienceChart}
        series={OPERATORS_QUEUE}
        title="Аудитория за год"
        tooltipText={{
          days: 'Аудитория за',
          average: 'Средняя аудитория за'
        }}
      />
      <Stats
        data={{
          year: fetchedCharts.audience_stats_year.data,
          period: fetchedCharts.audience_stats_period.data
        }}
        titles={{
          year: {
            all: `Аудитория с 01.01 по ${formattedDateTo} - средненедельное значение`,
            types: `Аудитория с 01.01 по ${formattedDateTo} (федеральные/региональные)- средненедельное значение `,
            regions: `Аудитория с 01.01 по ${formattedDateTo} (макрорегионы) - средненедельное значение`
          },
          period: {
            all: `Аудитория за период с ${formattedDateFrom} по ${formattedDateTo} - средненедельное значение`,
            types: `Аудитория за период с ${formattedDateFrom} по ${formattedDateTo} (федеральные/региональные) - средненедельное значение`,
            regions: `Аудитория за период с ${formattedDateFrom} по ${formattedDateTo} (макрорегионы) - средненедельное значение`
          }
        }}
        compact
        outerData={{
          regions,
          OPERATORS_QUEUE
        }}
      />
    </Tab>
  );

  const TabTopInfopovodov = () => (
    <Tab tab={TABS_NAMES.top_infopovodov}>
      <Chart
        type="StackedBarChart"
        dataSource={top_infopovodov_chart_data}
        series={[...OPERATORS_QUEUE, OPERATOR_BIG4]}
        row
        title={`Рейтинг инфоповодов за период с ${formattedDateFrom} по ${formattedDateTo}`}
      />
      <Chart
        type="CombineRowBarChart"
        title={`Статистика инфоповодов за неделю с ${moment(
          settings.dateTo,
          'YYYY-MM-DD'
        )
          .subtract(6, 'day')
          .format('DD.MM.YYYY')} по ${formattedDateTo}`}
        dataSource={infopovodov_stats}
        series={[
          {
            name: 'МТС',
            valueField: 'mts_count',
            type: 'spline',
            color: 'rgb(235, 90, 64)',
            stack: 'operator',
            showInLegend: true
          },
          {
            name: 'Билайн',
            valueField: 'beeline_count',
            type: 'spline',
            color: 'rgb(255, 167, 23)',
            stack: 'operator',
            showInLegend: true
          },
          {
            name: 'МегаФон',
            valueField: 'megafon_count',
            type: 'spline',
            color: 'rgb(0, 185, 86)',
            stack: 'operator',
            showInLegend: true
          },
          {
            name: 'Теле2',
            valueField: 'tele2_count',
            type: 'spline',
            color: 'rgb(68, 65, 137)',
            stack: 'operator',
            showInLegend: true
          },
          {
            name: 'YOTA',
            valueField: 'yota_count',
            type: 'spline',
            color: 'rgb(2, 185, 255)',
            stack: 'operator',
            showInLegend: true
          },
          {
            name: 'Общие',
            valueField: 'big4_count',
            type: 'spline',
            color: 'gray',
            stack: 'operator',
            showInLegend: true
          }
        ]}
        withDefaultTooltip={false}
      />
    </Tab>
  );

  const top3_pr_campaigns_charts_data = React.useMemo(() => {
    if (
      !fetchedCharts?.top3_pr_campaigns ||
      !fetchedCharts.top3_pr_campaigns.data?.federal ||
      !fetchedCharts.top3_pr_campaigns.data?.regional
    ) {
      return {
        federal: {
          audition: [],
          mentions: []
        },
        regional: {
          audition: [],
          mentions: []
        }
      };
    }

    const transformData = (data, byField) => {
      return data.map(item => ({
        [item.operator_name || 'big4']: item[byField],
        label: item.title
      }));
    };

    const { data = null } = fetchedCharts.top3_pr_campaigns;

    const federalData = {
      audition: transformData(data.federal.audition, 'audition'),
      mentions: transformData(data.federal.mentions, 'count')
    };

    const regionalData = {
      audition: transformData(data.regional.audition, 'audition'),
      mentions: transformData(data.regional.mentions, 'count')
    };

    return {
      federal: federalData,
      regional: regionalData
    };
  }, [fetchedCharts?.top3_pr_campaigns]);

  const average_pr_campaigns_charts_data = React.useMemo(() => {
    if (
      !fetchedCharts?.pr_campaigns?.data?.federal ||
      !fetchedCharts?.pr_campaigns?.data?.regional
    ) {
      return {
        federal: {
          posts: [],
          audience: []
        },
        regional: {
          posts: [],
          audience: []
        }
      };
    }

    const { federal, regional } = fetchedCharts.pr_campaigns.data;

    const transformData = data => {
      return data.reduce(
        (averageData, item) => ({
          posts: [
            ...averageData.posts,
            {
              [item.operator_name || 'big4']: item.average_posts,
              label: ''
            }
          ],
          audience: [
            ...averageData.audience,
            {
              [item.operator_name || 'big4']: item.average_audience,
              label: ''
            }
          ]
        }),
        {
          posts: [],
          audience: []
        }
      );
    };

    return {
      federal: transformData(federal.average_stats),
      regional: transformData(regional.average_stats)
    };
  }, [fetchedCharts?.pr_campaigns]);

  const AveragePostsAndAudience = ({ type }) => {
    if (!fetchedCharts?.pr_campaigns) {
      return <></>;
    }

    const { data } = fetchedCharts[`pr_campaigns_avg_weekly_stats_${type}`];

    const getDividers = () => {
      const getMaxVal = field =>
        data.reduce(
          (maxVal, item) => (item[field] > maxVal ? item[field] : maxVal),
          0
        );

      const maxAudience = getMaxVal('audience');
      const maxPublications = getMaxVal('publications');

      return {
        publications:
          maxPublications > 1_000_000
            ? 1_000_000
            : maxPublications > 1_000
            ? 1_000
            : 1,
        audience: maxAudience > 1_000_000 ? 1_000_000 : 1_000
      };
    };

    const getWordByDivider = divider => {
      if (divider === 1) {
        return 'единицы';
      }

      if (divider === 1_000) {
        return 'тыс.';
      }

      return 'млн.';
    };

    const dividers = getDividers();

    const defaultTitle = 'Среднее количество публикаций и аудитория';
    const title = `${defaultTitle}`;

    return (
      <Chart
        type="CombineRowBarChart"
        title={title}
        dataSource={data.map(item => ({
          ...item,
          date: moment(item.date).format('DD.MM.YYYY')
        }))}
        dividers={dividers}
        series={[
          {
            name: 'Публикации',
            valueField: 'publications',
            type: 'bar',
            color: '#85DDAE'
          },
          {
            name: 'Аудитория',
            valueField: 'audience',
            type: 'spline',
            color: '#731982'
          }
        ]}
      />
    );
  };

  const TabPrCampaignsFederal = () => (
    <div className={styles.Charts}>
      <div className={styles.Charts__Row}>
        <div className={styles.Charts__SubTitle}>
          Топ-3 кампании по публикациям
        </div>
        <Chart
          type="StackedBarChart"
          dataSource={top3_pr_campaigns_charts_data.federal.mentions}
          series={[...OPERATORS_QUEUE, OPERATOR_BIG4]}
          row
        />
        <div className={styles.Charts__SubTitle}>
          Топ-3 кампании по аудитории
        </div>
        <Chart
          type="StackedBarChart"
          dataSource={top3_pr_campaigns_charts_data.federal.audition}
          series={[...OPERATORS_QUEUE, OPERATOR_BIG4]}
          row
        />
      </div>
      <div className={styles.Charts__Row}>
        <div className={styles.Charts__HalfScreenContainer}>
          <div className={styles.Charts__SubTitle}>
            Среднее количество публикаций на PR-кампанию
          </div>
          <Chart
            type="StackedBarChart"
            dataSource={average_pr_campaigns_charts_data.federal.posts}
            series={OPERATORS_QUEUE}
            row
          />
        </div>
        <div className={styles.Charts__HalfScreenContainer}>
          <div className={styles.Charts__SubTitle}>
            Средняя аудитория на PR-кампанию
          </div>
          {console.log(average_pr_campaigns_charts_data.federal.audience)}
          <Chart
            type="StackedBarChart"
            dataSource={average_pr_campaigns_charts_data.federal.audience}
            series={OPERATORS_QUEUE}
            row
          />
        </div>
      </div>
      <div className={styles.Charts__SubTitle}>
        Статистика по всем PR-кампаниям (федеральным и региональным)
      </div>
      <Table
        header={prCampaignTableHeader}
        rows={prCampaignTableData['all']}
        settings={{
          withoutSort: true
        }}
      />
      <div className={styles.Charts__SubTitle}>
        Статистика по федеральным PR-кампаниям
      </div>
      <Table
        header={prCampaignTableHeader}
        rows={prCampaignTableData['federal']}
        settings={{
          withoutSort: true
        }}
      />
      <PRCampaigns type="federal" />
      <AveragePostsAndAudience type="federal" />
    </div>
  );

  const TabPrCampaignsRegional = () => (
    <div className={styles.Charts}>
      <div className={styles.Charts__Title}>PR-кампании (Региональные)</div>
      <div className={styles.Charts__Row}>
        <div className={styles.Charts__SubTitle}>
          Топ-3 кампании по публикациям
        </div>
        <Chart
          type="StackedBarChart"
          dataSource={top3_pr_campaigns_charts_data.regional.mentions}
          series={[...OPERATORS_QUEUE, OPERATOR_BIG4]}
          row
        />
        <div className={styles.Charts__SubTitle}>
          Топ-3 кампании по аудитории
        </div>
        <Chart
          type="StackedBarChart"
          dataSource={top3_pr_campaigns_charts_data.regional.audition}
          series={[...OPERATORS_QUEUE, OPERATOR_BIG4]}
          row
        />
      </div>
      <div className={styles.Charts__Row}>
        <div className={styles.Charts__HalfScreenContainer}>
          <div className={styles.Charts__SubTitle}>
            Среднее количество публикаций на PR-кампанию
          </div>
          <Chart
            type="StackedBarChart"
            dataSource={average_pr_campaigns_charts_data.regional.posts}
            series={OPERATORS_QUEUE}
            row
          />
        </div>
        <div className={styles.Charts__HalfScreenContainer}>
          <div className={styles.Charts__SubTitle}>
            Средняя аудитория на PR-кампанию
          </div>
          <Chart
            type="StackedBarChart"
            dataSource={average_pr_campaigns_charts_data.regional.audience}
            series={OPERATORS_QUEUE}
            row
          />
        </div>
      </div>
      <div className={styles.Charts__SubTitle}>
        Статистика по всем PR-кампаниям (федеральным и региональным)
      </div>
      <Table
        header={prCampaignTableHeader}
        rows={prCampaignTableData['all']}
        settings={{
          withoutSort: true
        }}
      />
      <div className={styles.Charts__SubTitle}>
        Статистика по региональным PR-кампаниям
      </div>
      <Table
        header={prCampaignTableHeader}
        rows={prCampaignTableData['regional']}
        settings={{
          withoutSort: true
        }}
      />
      <PRCampaigns type="regional" />
      <AveragePostsAndAudience type="regional" />
    </div>
  );

  const TabMagistralThemes = () => (
    <>
      <div className={styles.Charts__Title}>Направления бизнеса</div>
      <div className={styles.Charts__Row}>
        {fetchedCharts?.magistral_themes ? (
          fetchedCharts.magistral_themes.data.length > 0 ? (
            fetchedCharts.magistral_themes.data.map(
              ({ theme, stats: preStats, delta: preDelta }, i) => {
                const stats = preStats
                  .map(stat => ({
                    ...stat,
                    operator: OPERATORS_QUEUE.find(
                      ({ key }) => key === stat.operator
                    ).name
                  }))
                  .sort(
                    (a, b) =>
                      OPERATORS.find(({ name }) => name === a.operator)?.key -
                      OPERATORS.find(({ name }) => name === b.operator)?.key
                  );

                const delta = preDelta
                  .map(stat => ({
                    ...stat,
                    operator: OPERATORS_QUEUE.find(
                      ({ key }) => key === stat.operator
                    ).name
                  }))
                  .sort(
                    (a, b) =>
                      OPERATORS.find(({ name }) => name === a.operator)?.key -
                      OPERATORS.find(({ name }) => name === b.operator)?.key
                  );

                return (
                  <div style={{ width: '100%' }}>
                    <div className={styles.Charts__SubTitle}>{theme}</div>
                    <div
                      className={styles.Charts__Row}
                      style={{
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}
                    >
                      <div className={styles.Charts__HalfScreenContainer}>
                        <Chart
                          type="PieChart"
                          dataSource={{
                            argumentField: 'operator',
                            palette: OPERATORS_QUEUE.sort(
                              (a, b) => a.id - b.id
                            ).map(({ color }) => color),
                            valueField: 'post_count',
                            values: stats
                          }}
                        />
                      </div>
                      <div className={styles.Charts__HalfScreenContainer}>
                        <div className={styles.Charts__Table}>
                          <table>
                            <thead>
                              <tr>
                                <th>PR-кампании</th>
                                <th />
                                <th style={{ textAlign: 'center' }}>
                                  Δ (за период)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {delta.map(
                                (
                                  { operator, post_count, campaign_count },
                                  idx
                                ) => (
                                  <tr>
                                    <th>{operator}</th>
                                    <th>{stats[idx].campaign_count}</th>
                                    <th style={{ textAlign: 'center' }}>{`${
                                      campaign_count === 0
                                        ? ''
                                        : campaign_count > 0
                                        ? '+'
                                        : '-'
                                    }${campaign_count}`}</th>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <h2 style={{ textAlign: 'center', margin: '100px auto' }}>
              Данных не найдено
            </h2>
          )
        ) : (
          <div className={styles.Progress} style={{ width: '100%' }}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </div>
    </>
  );

  const TabMood = () => (
    <>
      <Chart
        type="RowStackedChart"
        dataSource={mood_stats_chart_data}
        series={[
          {
            key: 'positive',
            name: 'Позитивно',
            color: 'rgb(0, 185, 86)'
          },
          {
            key: 'neutral',
            name: 'Нейтрально',
            color: 'gray'
          },
          {
            key: 'negative',
            name: 'Негативно',
            color: 'rgb(235, 90, 64)'
          }
        ]}
        argumentField="label"
        title={`Общая динамика за период с ${formattedDateFrom} по ${formattedDateTo}`}
        row
      />
      <Chart
        type="RowChartWithZoom"
        variant={CHART_VARIANTS.average}
        range={range}
        dataSource={getYearRowChartData(null, mood_chart_data)}
        series={OPERATORS_QUEUE}
        title="Динамика негативных упоминаний"
        tooltipText={{
          days: 'Количество негативных упоминаний за',
          average: 'Среднее количество негативных упоминаний за'
        }}
      />
    </>
  );

  const { user: { role } } = useAuth();

  const RenderTabsMap = {
    [TABS_NAMES.mentions_and_audience]: <TabMentionsAndAudience />,
    [TABS_NAMES.top_infopovodov]: <TabTopInfopovodov />,
    [TABS_NAMES.pr_campaigns_federal]: <TabPrCampaignsFederal />,
    [TABS_NAMES.pr_campaigns_regional]: <TabPrCampaignsRegional />,
    [TABS_NAMES.magistral_themes]: <TabMagistralThemes
      fetchedCharts={fetchedCharts}
	    OPERATORS_QUEUE={OPERATORS_QUEUE}
      OPERATORS={OPERATORS}
    />,
    [TABS_NAMES.mood]: <TabMood
      mood_stats_chart_data={mood_stats_chart_data}
	    formattedDateFrom={formattedDateFrom}
      formattedDateTo={formattedDateTo}
      range={range}
      OPERATORS_QUEUE={OPERATORS_QUEUE}
      getYearRowChartData={(v, p) => getYearRowChartData(v, p)}
      mood_chart_data={mood_chart_data}
    />,
    [TABS_NAMES.pr_value]: <PRValueTab
      settings={settings}
      type="federal"
      CHART_VARIANTS={CHART_VARIANTS}
      OPERATORS_QUEUE={OPERATORS_QUEUE}
      fetchedCharts={fetchedCharts}
      regions={regions}
      formattedDateTo={formattedDateTo}
      formattedDateFrom={formattedDateFrom}
      range={range}
      yearAudienceChartData={yearAudienceChartData}
      fetchYearAudienceChart={fetchYearAudienceChart}
      getYearRowChartData={(v) => getYearRowChartData(v)}
    />,
  };

  const TabWrapper = ({ tab }) => {
    const { id, charts } = tab;

    if (id === activeTab.id && fetchedCharts && charts) {
      const chartsHasBeenDownload = charts.every(chart =>
        Object.keys(fetchedCharts).includes(chart)
      );

      if (chartsHasBeenDownload) {
        return RenderTabsMap[id];
      }
    }

    if (id === activeTab.id) {
      return <Loader />;
    }

    return <></>;
  };

  const TabsContent = React.useCallback(() => (
    <>
      <TabWrapper tab={TABS.mentions_and_audience} />
      <TabWrapper tab={TABS.top_infopovodov} />
      <TabWrapper tab={TABS.pr_campaigns_federal} />
      <TabWrapper tab={TABS.pr_campaigns_regional} />
      <TabWrapper tab={TABS.magistral_themes} />
      <TabWrapper tab={TABS.mood} />
      {role === 'pr_manager' &&  <TabWrapper tab={TABS.pr_value} />}
    </>
  ), [activeTab, fetchedCharts]);

  const BackButton = () =>
    reportId ? (
      <Button
        variant="outlined"
        color="primary"
        style={{ width: 200 }}
        onClick={() => history.push('/reports_history')}
      >
        Назад
      </Button>
    ) : (
      <></>
    );

  const DownloadButton = () =>
    fetchedCharts ? (
      <div className={styles.TabsControl__Container}>
        <Button
          variant="outlined"
          color="primary"
          style={{ width: 200 }}
          onClick={() => getXLCharts(reportId)}
        >
          Скачать
        </Button>
      </div>
    ) : (
      <></>
    );

  const Actions = () => (
    <div className={styles.Actions}>
      <BackButton />
      <DownloadButton />
    </div>
  );

  return (
    <Page loading={loading}>
      <ChartsContext.Provider
        value={{
          regions,
          OPERATORS_QUEUE
        }}
      />
      {!reportId && settings && (
        <ChartControlHeader
          settings={settings}
          handleUpdate={(updatedSettings, reportId) => {
            setReportId(reportId);
            setSettings(updatedSettings);
            history.push(`/report/${reportId}`);
            history.go(0);
          }}
        />
      )}
      {settings && fetchedCharts && (
        <div className={styles.OuterContainer}>
          <div className={styles.Wrapper}>
            <Actions />
            <TabsControl />
            <TabsContent />
          </div>
        </div>
      )}
    </Page>
  );
};

export default Charts;
