// @flow

// Libraries
import * as React from 'react';
import SplitPane from 'react-split-pane';
import SortableTree from 'react-sortable-tree';
import moment from 'moment';
import 'moment/locale/ru';
import _ from 'lodash';

// Api
import {
  dragNode,
  editArticle,
  getArticle,
  getChildArticles,
  getToken,
  globalFilterArticles,
  updateArticles
} from '../../api/articleApi';

import {
  addSomeTagsFromCatalog,
  getCustomTagsForPost,
  getTagConnection,
  removeTagFromArticle
} from '../../api/tagsApi';

// Components
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  LinearProgress,
  Modal,
  TextField,
  Tooltip
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhonelinkOffIcon from '@material-ui/icons/PhonelinkOff';
import PhonelinkIcon from '@material-ui/icons/Phonelink';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import CreateIcon from '@material-ui/icons/Create';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import TagsIcon from '@material-ui/icons/MoreOutlined';
import Page from '@components/Page';
import EditorManipulator from '@components/EditorManipulator';
import HandbookTagsModal from '@components/HandbookTagsModal';
import { StyledIconButton } from './Components';
import MonitoringOptions from './Components/Popovers/MonitoringOptions';
import EditModeForm from './Components/EditModeForm';
import CustomPopover from './Components/CustomPopover';
import ArticleContent from './Components/ArticleContent';
import Loader from '../../components/Loader';
import TagsForSelectedArticles from './Components/TagsForSelectedArticles';
import TagsForActiveArticle from './Components/TagsForActiveArticle';

// Constants
import { server } from '@constants/servers';
import { DEFAULT_MERGE_DATA, HANDBOOK_TAG_TYPES, MOODS } from './constants';

// Hooks
import { useSelector } from 'react-redux';

// Assets
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import FavoritesIcon from '../../assets/components/FavoritesIcon';
import folder from '@styles/tree-theme/folder.svg';

// Styles
import styles from './styles.sass';
import FileExplorerTheme from '../../styles/tree-theme';
import { Popover } from '@mui/material';
import QueryWrapper from "../../api/QueryWrapper";
import {Link, useLocation} from "react-router-dom";

export const COUNT_ARTICLES_IN_ONE_PAGE = 250;

const getDeclension = number => {
  // 1 - статья
  // 2, 3, 4  - статьи
  // 5, 6, 7, 8, 9, 10, 11, 12,  - статей

  if (number === 1 || (number % 10 === 1 && number >= 21)) {
    return 'статья';
  }

  if ([2, 3, 4].includes(number % 10) && number > 21) {
    return 'статьи';
  }

  return 'статей';
};

export const useDefaultEditorFilters = (prId) => {
  const [defaultRows, setDefaultRows] = React.useState([]);
  const {
    monitoring_sections: sections,
    monitoring_types: types,
    monitoring_regions: regions
  } = useSelector(store => store.monitoring_constants);

  const fetchRegions = async () => {
    await fetch(`${server}/api/v1/monitoring/branches`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    })
      .then(response => response.json())
      .then(res => {
        res &&
          setDefaultRows(
            res.map(r => ({
              value: r.region_id,
              label: r.region_name,
              region: r.name
            }))
          );
      });
  };

  React.useEffect(() => {
    fetchRegions();
  }, []);

  return {
    defaultFilters: {
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      processed: 'all',
      pr_campaigns: prId ? [prId] : [],
      types,
      sections,
      macroRegions: regions,
      regions: defaultRows,
      keyWords: [],
      tags: [],
      isFavorite: 'all',
      sort: {
        type: 'date',
        direction: 'asc'
      },
      parents_only: true
    }
  };
};

const EditorModule = () => {
  const {
    monitoring_sections: sections,
    monitoring_types: types,
    monitoring_regions: regions
  } = useSelector(store => store.monitoring_constants);

  const { state } = useLocation();
  const { user: userInfo } = useSelector(store => store);
  const { defaultFilters } = useDefaultEditorFilters(state?.prCampaignId);

  /**
   * Массив ПР компаний по которым нужно искать новости
   * **/
  const [selectedPrCampaign, setSelectedPrCampaign] = React.useState([]);

  const [activeArticle, setActiveArticle] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [articleList, setArticleList] = React.useState([]);
  const [activeArticleTags, setActiveArticleTags] = React.useState([]);
  const [activeArticlePRCampaign, setActiveArticlePRCampaign] = React.useState([]);
  const [isOpenHandbookModal, setIsOpenHandbookModal] = React.useState({
    show: false,
    type: null
  });
  const [isOpenOptionsPopover, setIsOpenOptionsPopover] = React.useState(false);
  const [event, setEvent] = React.useState({
    open: false,
    message: null,
    status: null
  });
  const [showGroupPopover, setShowGroupPopover] = React.useState(false);
  const [showCheckboxPopover, setShowCheckboxPopover] = React.useState(false);
  const [groupTags, setGroupTags] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [pagesCount, setPagesCount] = React.useState(1);
  const [showMoodTooltip, setShowMoodTooltip] = React.useState(false);

  // refactor
  const [totalCount, setTotalCount] = React.useState(0);
  const [loadings, setLoadings] = React.useState({
    global: true,
    favorites: []
  });
  const [filters, setFilters] = React.useState(defaultFilters);
  const [hideImagesInArticle, setHideImagesInArticle] = React.useState(false);
  const [checkedArticles, setCheckedArticles] = React.useState([]);
  const [mergeData, setMergeData] = React.useState(DEFAULT_MERGE_DATA);
  const [anchorAudiencePopover, setAnchorAudiencePopover] = React.useState(
    null
  );
  const [actualAudition, setActualAudition] = React.useState(
    activeArticle?.audition
  );
  const [editData, setEditData] = React.useState(null);
  const [isPendingArticleEdit, setIsPendingArticleEdit] = React.useState(false);

  const activeArticleParent = React.useMemo(() => {
    if (articleList && activeArticle) {
      if (activeArticle.post_id === null) {
        return activeArticle;
      }

      return articleList.find(({ id }) => id === activeArticle.post_id);
    }

    return null;
  }, [articleList, activeArticle]);

  React.useEffect(() => {
    setActualAudition(activeArticle?.audition);
  }, [activeArticle]);

  const handleChangeLoadings = React.useCallback(values =>
    setLoadings(prev => ({ ...prev, ...values }), [loadings])
  );

  const refetchArticles = async properties => {
    handleChangeLoadings({ global: true });

    const unresolvedPromises = await [
      ...Array(properties?.pages ? 2 : pagesCount + 1).keys()
    ]
      .slice(
        properties?.pages
          ? 1
          : !properties?.totalRefetch
          ? articleList.length / COUNT_ARTICLES_IN_ONE_PAGE + 1
          : 1
      )
      .map(async pageNumber => {
        return await globalFilterArticles(filters, pageNumber);
      });

    let total = 0;

    const articlesListBasedOnPagesRange = await Promise.all(
      unresolvedPromises
    ).then(res =>
      res.reduce((fullData, { data, total_count }) => {
        if (total_count !== 0) {
          setTotalCount(total_count);
          total = total_count;
        }

        return Array.isArray(data) && total_count !== 0
          ? [...fullData, ...data]
          : fullData;
      }, [])
    );

    const articles = !properties?.totalRefetch
      ? [...articleList, ...articlesListBasedOnPagesRange]
      : articlesListBasedOnPagesRange;

    if (Array.isArray(articles)) {
      const neededFetchChildren = articles.filter(
        article => article.posts_count > 0
      );

      const childrenPromises = await neededFetchChildren.map(async article => {
        return await fetch(
          `${server}/api/v1/monitoring/posts/${article.id}/children`,
          {
            method: 'GET',
            headers: {
              'Content-Type': `application/json`,
              Authorization: `Bearer ${getToken()}`
            }
          }
        ).then(res => res.json());
      });

      const parentWithChildren = await Promise.all(childrenPromises).then(
        childrenGroups =>
          childrenGroups
            .map(children => {
              if (children.length > 0) {
                return {
                  ...articles.find(({ id }) => id === children[0].post_id),
                  posts_count: children.length + 1,
                  children
                };
              }

              return null;
            })
            .filter(children => children)
      );

      const fetchedArticles = articles.map(article => {
        const foundChild = parentWithChildren.find(
          ({ id }) => id === article.id
        );
        return foundChild || article;
      });

      preparingArticlesForTree(fetchedArticles, total);
    }
  };

  const preparingArticlesForTree = (articles, total = totalCount) => {
    const changedArticles = [...articles].filter(
      article => !article.hasOwnProperty('loader')
    );
    const listHasMoreItems =
      changedArticles.length > 0 && changedArticles.length + 2 < total;

    if (listHasMoreItems)
      changedArticles.push({
        loader: true
      });

    handleChangeLoadings({ global: false });
    setArticleList(changedArticles);
  };

  // При изменении параметров фильтрации
  React.useEffect(() => {
    if (!state?.prCampaignId) {
      refetchArticles();
    }
  }, [pagesCount, defaultFilters.regions]);

  React.useEffect(() => {
    setPagesCount(1);
    refetchArticles({ totalRefetch: true, pages: 1 });
  }, [filters]);

  /**
   * ФИЛЬТРАЦИЯ ПО ПР КОМПАНИЯМ
   * **/
  React.useEffect(() => {
    if (!state?.prCampaignId) {
      setFilters(p => ({
        ...p,
        pr_campaigns: selectedPrCampaign.map(i => i.id)
      }));
    }
  }, [selectedPrCampaign]);

  // швейцарский нож для редактирования одной новости
  const editOneArticle = async (article, values, messages) => {
    // проверяем есть ли родитель
    const checkForChild = articleList.findIndex(item =>
      item?.children?.find(item => item.id === article.id)
    );

    const editedArticle = {
      ...article,
      ...values
    };

    setIsPendingArticleEdit(true);

    await editArticle(
      {
        articleID: article.id,
        editedArticle: {
          ...values
        }
      },
      article => {
        setArticleList(articles => {
          const foundIndex = articles.findIndex(item => item.id === article.id);
          const editedArticles = [...articles];
          editedArticles[foundIndex] = editedArticle;

          if (checkForChild >= 0) {
            editedArticles[checkForChild] = {
              ...editedArticles[checkForChild],
              children: editedArticles[checkForChild].children.map(item => {
                if (item.id === editedArticle.id) {
                  return editedArticle;
                }

                return item;
              })
            };
          }

          return editedArticles;
        });

        setActiveArticle(article);
        setEvent({ open: true, message: messages.success, status: 'success' });
        setIsPendingArticleEdit(false);
      },
      () => {
        setEvent({ open: true, message: messages.error, status: 'error' });
      }
    );
  };

  // отправка новости в мониторинг
  const sendToMonitoringHandler = (type, region, section) => {
    editOneArticle(
      activeArticle,
      {
        monitoring_region: region,
        monitoring_section: section,
        monitoring_type: type,
        monitoring_date: moment()
          .add(1, 'days')
          .utc()
          .format('YYYY-MM-DDThh:mm:ss'),
        processed: true,
        status: 'in_process'
      },
      {
        success: 'Новость успешно добавлена в мониторинг',
        error: 'Произошла ошибка при добавлении новости в мониторинг'
      }
    );
  };

  // редактирование новости
  const editArticleHandler = editedArticle => {
    editOneArticle(activeArticle, editedArticle, {
      success: 'Новость успешно отредактирована',
      error: 'Произошла ошибка при редактировании новости'
    });
  };

  // удалить одну новость из мониторинга (если папка, то и вложенные)
  const handleDeleteFromMonitoring = () => {
    if (activeArticle.hasOwnProperty('children')) {
      changeGroupArticles(
        { monitoring_date: null, status: 'not_in_monitoring' },
        {
          success:
            'Родительская новость и вложенные новости успешно удалены из мониторинга',
          error: 'Произошла ошибка при удалении новостей из мониторинга'
        }
      );
    } else {
      editOneArticle(
        activeArticle,
        { monitoring_date: null, status: 'not_in_monitoring' },
        {
          success: 'Новость успешно удалена из мониторинга',
          error: 'Произошла ошибка при удалении новости из мониторинга'
        }
      );
    }
  };

  // изменение настроения новости
  // const editMood = mood => {
  //   editOneArticle(
  //     activeArticle,
  //     { mood },
  //     {
  //       success: 'Тональность успешно изменена',
  //       error: 'Произошла ошибка при изменении тональности новости'
  //     }
  //   );
  // };

  // установка статуса "обработано"
  const sendArticleInProcessed = () => {
    editOneArticle(
      activeArticle,
      { processed: true },
      {
        success: 'Статус "обработано" был успешно установлен',
        error: 'Произошла ошибка при установке статуса "обработано"'
      }
    );
  };

  const handleEditSave = React.useCallback(() => {
    editArticleHandler(editData);
    setEditMode(false);
    setShowAlert(false);
  }, [editData]);

  const handleEditCancel = () => {
    setEditMode(false);
  };

  /**
   * РАБОТА С ОДНОЙ НОВОСТЬЮ
   *
   * Хэндлер нажатия на новость, её получение, и формирование тегов
   * **/
  const onSelectActiveArticle = node => {
    getArticle(
      node.id,
      (res) => {

        const customTags = res.tags.map(tag => ({
          tag_type: 'custom',
          tag_id: tag?.id,
          title: tag?.title,
          checked: true,
        }));

        const paidMarker = res.paid === null ? [] :
          res.paid
            ? [{title: "Органика", tag_id: 9999, tag_type: 'official', checked: true}]
            : [{title: "Не органика", tag_id: 9998, tag_type: 'official', checked: true}];

        const pr_campaigns = res.pr_campaigns.map(tag => ({
          tag_type: 'pr_campaign',
          tag_id: tag?.id,
          title: tag?.name,
          checked: true,
        }));

        setActiveArticleTags([...customTags, ...paidMarker]);
        setActiveArticlePRCampaign(pr_campaigns);
        setActiveArticle(res);
      },
      () => {
        setActiveArticleTags([])
        setActiveArticlePRCampaign([])
      }
    );
  };
  /**
   * Отвязка тега, пркомпании, орган\неорган от одной выбранной новости
   * **/
  const removeTag = async ({ post_id, tag_type, tag_id }) => {
    if (tag_type === 'custom') {
      const tagByType = activeArticleTags.filter(tag => tag.tag_type === 'custom');
      const result = tagByType.filter(item => item.tag_id !== tag_id);
      await QueryWrapper(
        fetch(`${server}/api/v1/monitoring/posts/${post_id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            tags: result.map(item => item.tag_id)
          }),
          headers: {
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${getToken()}`
          }
        }),
        (res) => {
          onSelectActiveArticle(activeArticle);
        }
      )
    }

    if (tag_type === 'pr_campaign') {
      const tagByType = activeArticlePRCampaign.filter(tag => tag.tag_type === 'pr_campaign');
      const result = tagByType.filter(item => item.tag_id !== tag_id);
      await QueryWrapper(
        fetch(`${server}/api/v1/monitoring/posts/${post_id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            pr_campaigns: result.map(item => item.tag_id)
          }),
          headers: {
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${getToken()}`
          }
        }),
        (res) => {
          onSelectActiveArticle(activeArticle);
        }
      )
    }

    if (tag_type === 'official') {
      await QueryWrapper(
        fetch(`${server}/api/v1/monitoring/posts/${post_id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            paid: null
          }),
          headers: {
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${getToken()}`
          }
        }),
        (res) => {
          onSelectActiveArticle(activeArticle);
        }
      )
    }
  };


  /**
   * РАБОТА С НОВОСТЯМИ ВЫБРАННЫМИ ЧЕРЕЗ ЧЕКБОКСЫ
   *
   * Метод для получения новостей с нажатым чекбоксом
   * **/
  const fetchCheckedArticles = async (Ids) => {
    await fetchAllTagsInGroup(Ids).then(res => {
      const tagsFromNews = [];

      res.map(item => {
        item.tags.forEach(tag => {
          if (!tagsFromNews.find(e => e.tag_id === tag.id)) {
            tagsFromNews.push({
              tag_id: tag.id,
              title: tag.title,
              tag_type: tag.type,
              show: true
            })
          } else {
            tagsFromNews.push({
              tag_id: tag.id,
              title: tag.title,
              tag_type: tag.type,
              show: false
            })
          }
        });
        item.pr_campaigns.forEach(pr => {
          if (!tagsFromNews.find(e => e.tag_id === pr.id)) {
            tagsFromNews.push({
              tag_id: pr.id,
              title: pr.name,
              tag_type: 'pr_campaign',
              show: true
            })
          } else {
            tagsFromNews.push({
              tag_id: pr.id,
              title: pr.name,
              tag_type: 'pr_campaign',
              show: false
            })
          }
        });
      });

      setCheckedArticles(res);
      setSelectedTags(tagsFromNews);
    });
  };
  /**
   * Хэндлер нажатия на чекбокс новости
   * **/
  const handlerCheckboxArticleList = async node => {
    let allArticlesWithCheck = [...checkedArticles];

    // получение всех дочерних новостей
    const allChildrens = articleList.filter(({ children }) => children).map(({ children }) => children).flat();

    // поиск новости по чекбоксу которой был клик
    const currentArticle = [...articleList, ...allChildrens].find(({ id }) => node.id === id);
    // поиск индекса новости по чекбоксу которой был клик
    const indexOfCheckedArticles = allArticlesWithCheck.findIndex(({ id }) => id === node.id);
    // определяем является ли данная новость родительской
    const currentArticleIsParent = allChildrens.find(({ post_id }) => post_id === node.id);

    if (indexOfCheckedArticles >= 0) {
      allArticlesWithCheck.splice(indexOfCheckedArticles, 1);

      if (currentArticleIsParent) {
        // массив с исклёченными дочерними новостями, при отжатии чекбокса на родительской новости
        // const restructedArray = allArticlesWithCheck.filter(item => !item.post_id || item.post_id !== node.id)
        // allArticlesWithCheck = restructedArray;
      }
    } else {
      // массив с всеми дочерними новостями, при прожатии чекбокса на родительской новости
      // const childrensForCurrentNode = currentArticleIsParent && allChildrens.filter(({ post_id }) => post_id === node.id);
      allArticlesWithCheck.push(currentArticle);

      // выбор и запись родительсой новости со всеми её дочерними новостями
      // if (currentArticleIsParent) {
      //   allArticlesWithCheck = [...allArticlesWithCheck, ...childrensForCurrentNode];
      // }

      // авто прожатие родительского чекбокса, если прожата хотя бы одна дочерняя новость
      // TODO: временно не нужно, но требования меняются постоянно
      // if (node.post_id && !allArticlesWithCheck.find(({ id }) => id === node.post_id)) {
      //   allArticlesWithCheck.push(articleList.find(item => item.id === node.post_id));
      // }
    }

    const mapedIds = allArticlesWithCheck.filter(item => item !== undefined).map(({ id }) => id);
    const Ids = mapedIds.filter(item => (typeof item === 'number'));

    await fetchCheckedArticles(Ids)
  };
  /**
   * Отвязка тега, пркомпании, орган\неорган от выделенных через чекбокс новостей
   * **/
  const removeSelectedTag = async tag => {
    const mapedIds = checkedArticles.filter(item => item !== undefined).map(({ id }) => id);
    const Ids = mapedIds.filter(item => (typeof item === 'number'));

    if (tag) {
      checkedArticles.map(async article => {
        if (tag.tag_type === 'custom') {
          if (article.tags.find(t => t.id === tag?.tag_id)) {
            const result = article.tags.filter(item => item.id !== tag?.tag_id);
            await QueryWrapper(
              fetch(`${server}/api/v1/monitoring/posts/${article.id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                  tags: result.map(item => item.id)
                }),
                headers: {
                  'Content-Type': `application/json`,
                  'Authorization': `Bearer ${getToken()}`
                }
              }),
              () => {
                fetchCheckedArticles(Ids);
              }
            )
          }
        }
        if (tag.tag_type === 'pr_campaign') {
          const result = article.pr_campaigns.filter(item => item.id !== tag?.tag_id);
          await QueryWrapper(
            fetch(`${server}/api/v1/monitoring/posts/${article.id}`, {
              method: 'PATCH',
              body: JSON.stringify({
                pr_campaigns: result.map(item => item.id)
              }),
              headers: {
                'Content-Type': `application/json`,
                'Authorization': `Bearer ${getToken()}`
              }
            }),
            () => {
              fetchGroupTags(Ids);
            }
          )
        }
        if (tag.tag_type === 'official') {
          await QueryWrapper(
            fetch(`${server}/api/v1/monitoring/posts/${article.id}`, {
              method: 'PATCH',
              body: JSON.stringify({
                paid: null
              }),
              headers: {
                'Content-Type': `application/json`,
                'Authorization': `Bearer ${getToken()}`
              }
            }),
            () => {
              fetchGroupTags(Ids);
            }
          )
        }
      })
    }
  };

  const buildDataGroup = () => {
    const allChildren = articleList.filter(({ children }) => children).map(({ children }) => children).flat();
    const currentChildren = allChildren.filter(item => item.post_id === activeArticle.id);

    return [...currentChildren, activeArticle].map(i => i.id);
  };

  /**
   * РАБОТА С НОВОСТЯМИ ИЗ ГРУППЫ (ВЛОЖЕННЫЕ)
   *
   * Метод для получения тегов из новостей группы
   * **/
  const fetchGroupTags = async (forSelected = false) => {
    if (!forSelected) {
      const { children = [] } = articleList.find(
        article => article.id === activeArticle.id
      );
      const Ids = [activeArticle.id, ...children.map(({ id }) => id)];
      await fetchAllTagsInGroup(Ids).then(res => {
        const tagsFromNews = [];

        res.map(item => {
          item.tags.forEach(tag => {
            if (!tagsFromNews.find(e => e.tag_id === tag.id)) {
              tagsFromNews.push({
                tag_id: tag.id,
                title: tag.title,
                tag_type: tag.type,
                show: true
              })
            } else {
              tagsFromNews.push({
                tag_id: tag.id,
                title: tag.title,
                tag_type: tag.type,
                show: false
              })
            }
          });
          item.pr_campaigns.forEach(pr => {
            if (!tagsFromNews.find(e => e.tag_id === pr.id)) {
              tagsFromNews.push({
                tag_id: pr.id,
                title: pr.name,
                tag_type: 'pr_campaign',
                show: true
              })
            } else {
              tagsFromNews.push({
                tag_id: pr.id,
                title: pr.name,
                tag_type: 'pr_campaign',
                show: false
              })
            }
          });
        });
        setGroupTags(tagsFromNews);
      });
    }
  };

  /**
   * РАБОТА С НОВОСТЯМИ ИЗ ГРУППЫ (ВЛОЖЕННЫЕ)
   *
   * Метод для удаления тега из новостей группы
   * **/
  const removeGroupTag = async tag => {
    const { children = [] } = articleList.find(
      article => article.id === activeArticle.id
    );
    const articlesIds = [activeArticle, ...children].map(i => i.id);


    if (tag) {
      articlesIds.map(async aid => {
        await getArticle(
          aid,
          async (res) => {
            if (tag.tag_type === 'custom') {
              if (res.tags.find(t => t.id === tag?.tag_id)) {
                const result = res.tags.filter(item => item.id !== tag?.tag_id);
                await QueryWrapper(
                  fetch(`${server}/api/v1/monitoring/posts/${res.id}`, {
                    method: 'PATCH',
                    body: JSON.stringify({
                      tags: result.map(item => item.id)
                    }),
                    headers: {
                      'Content-Type': `application/json`,
                      'Authorization': `Bearer ${getToken()}`
                    }
                  }),
                  () => {
                    fetchGroupTags();
                  }
                )
              }
            }
            if (tag.tag_type === 'pr_campaign') {
              const result = res.pr_campaigns.filter(item => item.id !== tag?.tag_id);
              await QueryWrapper(
                fetch(`${server}/api/v1/monitoring/posts/${res.id}`, {
                  method: 'PATCH',
                  body: JSON.stringify({
                    pr_campaigns: result.map(item => item.id)
                  }),
                  headers: {
                    'Content-Type': `application/json`,
                    'Authorization': `Bearer ${getToken()}`
                  }
                }),
                () => {
                  fetchGroupTags();
                }
              )
            }
            if (tag.tag_type === 'official') {
              await QueryWrapper(
                fetch(`${server}/api/v1/monitoring/posts/${res.id}`, {
                  method: 'PATCH',
                  body: JSON.stringify({
                    paid: null
                  }),
                  headers: {
                    'Content-Type': `application/json`,
                    'Authorization': `Bearer ${getToken()}`
                  }
                }),
                () => {
                  fetchGroupTags();
                }
              )
            }
          })
      })
    }
  };


  const dragNodeHandler = (parentNode, childNode) => {
    dragNode({
      parentNode,
      childNode
    });

    const getEditedArticleList = (articles, index, value) => {
      const editedArticles = [...articles];
      editedArticles[index] = {
        ...editedArticles[index],
        posts_count: editedArticles[index].posts_count + value,
        childIsAdded: false,
        childIsRemoved: false
      };
      return editedArticles;
    };

    setArticleList(articles => {
      const addedElemIndex = articles.findIndex(
        article => article.childIsAdded
      );

      if (addedElemIndex > -1) {
        return getEditedArticleList(articles, addedElemIndex, 1);
      }

      const removedElemIndex = articles.findIndex(
        article => article.childIsRemoved
      );

      if (removedElemIndex > -1) {
        return getEditedArticleList(articles, removedElemIndex, -1);
      }

      return articles;
    });
  };

  // швейцарский нож для изменения группы новостей
  const changeGroupArticles = (fields = {}, messages) => {
    handleChangeLoadings({ global: true });

    const changeGroup = async children => {
      const activeArticleWithoutChildren = { ...activeArticle };
      delete activeArticleWithoutChildren.children;

      const changedArticles = [
        activeArticleWithoutChildren,
        ...children.map(child => ({
          ...child,
          post_id: activeArticle.id
        }))
      ];

      const articlesWithChanges = changedArticles.map(article => ({
        ...article,
        ...fields
      }));

      await updateArticles(
        articlesWithChanges,
        givenArticles => {
          setArticleList(articles => {
            const parentIndex = articles.findIndex(
              article => article.id === activeArticleWithoutChildren.id
            );
            const articlesWithUpdates = [...articles];
            const [parent, children] = [
              givenArticles.find(
                article => article.id === activeArticleWithoutChildren.id
              ),
              givenArticles
                .filter(
                  article => article.id !== activeArticleWithoutChildren.id
                )
                .map(article => ({
                  ...article,
                  ...fields
                }))
            ];

            // apply changes
            articlesWithUpdates[parentIndex] = {
              ...parent,
              ...fields,
              expanded: true, // чтобы дерево оставалось открытым
              children: [...children]
            };

            setActiveArticle(prev => ({
              ...prev,
              ...fields,
              children: [...children]
            }));

            return articlesWithUpdates;
          });

          setEvent({
            open: true,
            message: messages?.success || 'Успешно',
            status: 'success'
          });
        },
        () =>
          setEvent({
            open: true,
            message: messages?.error || 'Ошибка',
            status: 'error'
          })
      );

      handleChangeLoadings({ global: false });
    };

    if (activeArticle.children) {
      changeGroup(activeArticle.children);
    } else {
      getChildArticles(activeArticle.id, fetchedChildren =>
        changeGroup(fetchedChildren)
      );
    }
  };

  // пометить группу как обработанные
  const handleGroupProcessed = () =>
    changeGroupArticles(
      { processed: true },
      {
        success: 'Вложенные новости были помечены как обработанные',
        error: 'Возникла ошибка при пометке вложенных новостей'
      }
    );

  // удалить группу из мониторинга
  const handleGroupDeleteFromMonitoring = () =>
    changeGroupArticles(
      { monitoring_date: null },
      {
        success: 'Вложенные новости были удалены из мониторинга',
        error: 'Возникла ошибка при удалении вложенных новостей из мониторинга'
      }
    );

  // изменить тональность группы новостей
  const handleGroupMood = mood =>
    changeGroupArticles(
      { mood },
      {
        success: 'Тональность для вложенных новостей успешно изменена',
        error:
          'Возникла ошибка при изменении тональности для вложенных новостей'
      }
    );

  // отправить в мониторинг группу новостей
  const handleGroupToMonitoring = (type, region, section) =>
    changeGroupArticles(
      {
        monitoring_date: moment()
          .add(1, 'days')
          .utc()
          .format('YYYY-MM-DDThh:mm:ss'),
        monitoring_type: type,
        monitoring_region: region,
        monitoring_section: section,
        processed: true,
        status: 'in_process'
      },
      {
        success: 'Вложенные новости успешно добавлены в мониторинг',
        error: 'Возникла ошибка при добавлении вложенных новостей в мониторинг'
      }
    );

  // Поменять родителя у группы (берется из article)
  const swapGroupParent = async () => {
    const parent = articleList.find(
      article => article.id === activeArticle.post_id
    );
    const children = parent.children.filter(
      child => child.id !== activeArticle.id
    );
    const updatedParent = {
      ...parent,
      post_id: activeArticle.id,
      posts_count: 0
    };
    delete updatedParent.children;
    const updatedChildren = children.map(child => ({
      ...child,
      post_id: activeArticle.id
    }));
    const newChildren = [updatedParent, ...updatedChildren];
    const newParent = {
      ...activeArticle,
      post_id: null,
      posts_count: newChildren.length + 1,
      children: newChildren
    };

    const updatedArticles = [...newChildren, newParent];

    await updateArticles(
      updatedArticles,
      () => {
        setArticleList(articles => {
          const foundIndex = articles.findIndex(
            article => article.id === updatedParent.id
          );
          const editedArticles = [...articles];
          editedArticles[foundIndex] = {
            ...newParent,
            expanded: true // чтобы дерево оставалось открытым
          };
          return editedArticles;
        });
        setActiveArticle(newParent);
        setEvent({
          open: true,
          message: 'Родитель успешно изменен!',
          status: 'success'
        });
      },
      () =>
        setEvent({
          open: true,
          message: 'Неудалось изменить родителя!',
          status: 'error'
        })
    );
  };

  const fetchAllTagsInGroup = async Ids => {
    const fetchTags = Ids.map(async id => {
      const req = await fetch(
        `${server}/api/v1/monitoring/posts/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': `application/json`,
            Authorization: `Bearer ${getToken()}`
          }
        }
      );

      return req.json();
    });

    return await Promise.all(fetchTags);
  };

  // Работа с отмеченными чекбоксами новостями
  const checkboxManipulator = async (
    updatedArticles,
    messages,
    isAddToFavorites
  ) => {
    await updateArticles(
      updatedArticles,
      () => {
        if (isAddToFavorites) {
          setArticleList(articles => {
            const editedArticles = articles.map(article => {
              const updatedArticle = updatedArticles.find(
                updArticle => updArticle.id === article.id
              );
              return updatedArticle ? updatedArticle : article;
            });

            // берем родителей у всех детей (если есть родитель) и меняем им поле children
            const children = updatedArticles.filter(
              article => article.post_id !== null
            );
            const parents = articles.filter(
              ({ id }) =>
                children && children.find(({ post_id }) => post_id === id)
            );
            // ИСКАТЬ БАГУЛЮ ЗДЕСЬ!!!!!!!!!!!
            const updatedParents = parents.map(parent => {
              // дети этого родителя, которые были изменены
              try {
                const updatedChildren = parent.children.filter(child =>
                  updatedArticles.find(updArt => updArt.id === child.id)
                );
                // const updatedChildren = updatedArticles.filter(child => parent.children.find(updArt => updArt.id === child.id));
                const foundChangedParent = updatedArticles.find(
                  article => article.id === parent.id
                );
                const updatedParent = foundChangedParent || parent;
                return {
                  ...updatedParent,
                  children: parent.children.map(child => {
                    const foundedChild = updatedChildren.find(
                      updChild => updChild.id === child.id
                    );
                    return foundedChild ? foundedChild : child;
                  })
                };
              } catch (err) {}
            });

            return editedArticles.map(editedArticle => {
              const foundedParent = updatedParents.find(
                updParent => updParent.id === editedArticle.id
              );
              return foundedParent ? foundedParent : editedArticle;
            });
          });
        } else {
          refetchArticles({ totalRefetch: true });
        }
        // setActiveArticle(prev => updatedArticles.find(article => article.id === activeArticle.id) || prev);
        setCheckedArticles([]);
        // берем id родителя у всех детей (если есть родитель) и меняем ему поле children
        messages &&
          setEvent({
            open: true,
            message: messages.success,
            status: 'success'
          });
      },
      () =>
        messages &&
        setEvent({ open: true, message: messages.error, status: 'error' })
    );
  };

  // пометить как обработанные
  const checkboxProcessed = () => {
    checkboxManipulator(
      checkedArticles.map(article => ({
        ...article,
        pr_campaigns: article.pr_campaigns.map(i => i.id),
        tags: article.tags.map(i => i.id),
        processed: true
      })),
      {
        success: 'Выделенные новости успешно помеченны как обработанные',
        error: 'Произошла ошибка при пометке новостей'
      }
    );
  };

  // поместить в мониторинг
  const checkboxToMonitoring = (type, region, section) => {
    setShowGroupPopover(false);
    checkboxManipulator(
      checkedArticles.map(article => ({
        ...article,
        status: article.status === 'published' ? article.status : 'in_process',
        monitoring_date: moment()
          .add(1, 'days')
          .utc()
          .format('YYYY-MM-DDThh:mm:ss'),
        monitoring_type: type,
        monitoring_region: region,
        monitoring_section: section,
        processed: true
      })),
      {
        success: 'Выделенные новости успешно отправлены в мониторинг',
        error: 'Произошла ошибка при отправке в мониторинг'
      }
    );
  };

  // удалить из мониторинга
  const checkboxDeleteFromMonitoring = () => {
    checkboxManipulator(
      checkedArticles.map(article => ({
        ...article,
        monitoring_date: null
      })),
      {
        success: 'Выделенные новости успешно удалены из мониторинга',
        error: 'Произошла ошибка при удалении из мониторинга'
      }
    );
  };

  /**
   * Изменение тональности новостей выделенных через чекбоксы**/
  const checkboxMood = mood => {
    checkboxManipulator(
      checkedArticles.map(article => ({
        ...article,
        pr_campaigns: article.pr_campaigns.map(i => i.id),
        tags: article.tags.map(i => i.id),
        mood
      })),
      {
        success: 'Настроение у выделенных новостей успешно изменено',
        error: 'Произошла ошибка при изменении настроения новостей'
      }
    );
  };

  const checkboxAddToFavorite = async () => {
    const willUseCheckedArticles = checkedArticles.filter(
      ({ is_favorite }) => !is_favorite
    );
    const checkedArticlesIds = willUseCheckedArticles.map(({ id }) => id);

    setLoadings({
      favorites: [...loadings.favorites, ...checkedArticlesIds]
    });

    await checkboxManipulator(
      willUseCheckedArticles.map(article => ({
        ...article,
        pr_campaigns: article.pr_campaigns.map(i => i.id),
        tags: article.tags.map(i => i.id),
        is_favorite: true
      })),
      {
        success: 'Выделенные новости добавлены в избранное',
        error: 'Произошла ошибка при добавлении в избранное'
      },
      true
    );

    setLoadings({
      favorites: loadings.favorites.filter(
        favoriteId => !checkedArticlesIds.includes(favoriteId)
      )
    });
  };

  const checkboxDeleteFromFavorite = async () => {
    const willUseCheckedArticles = checkedArticles.filter(
      ({ is_favorite }) => is_favorite
    );
    const checkedArticlesIds = willUseCheckedArticles.map(({ id }) => id);

    setLoadings({
      favorites: [...loadings.favorites, ...checkedArticlesIds]
    });

    await checkboxManipulator(
      willUseCheckedArticles.map(article => ({
        ...article,
        pr_campaigns: article.pr_campaigns.map(i => i.id),
        tags: article.tags.map(i => i.id),
        is_favorite: false
      })),
      {
        success: 'Выделенные новости удалены из избранного',
        error: 'Произошла ошибка при удалении из избранного'
      }
    );

    setLoadings({
      favorites: loadings.favorites.filter(
        favoriteId => !checkedArticlesIds.includes(favoriteId)
      )
    });
  };

  const handleLoading = props =>
    setMergeData(prev => ({
      ...prev,
      error: false,
      ...props
    }));

  const handleCloseModalLoading = (transition = 0) =>
    setTimeout(() => {
      setMergeData(DEFAULT_MERGE_DATA);
    }, transition);

  const callMerge = () => {
    handleLoading({
      showModal: true,
      title: 'Объединение статей',
      select: {
        needSelectParent: true,
        selected: '',
        allItemsForSelect: checkedArticles.filter(article => !article.post_id)
      }
    });
  };

  const checkboxMerge = () => {
    const parent = mergeData.select.selected;

    const updatedChildren = checkedArticles
      .filter(article => article.id !== parent.id)
      .map(article => {
        if (article?.children) {
          const updatedParent = { ...article };
          delete updatedParent.children;
          return [...article.children, updatedParent];
        }

        return article;
      })
      .flat()
      // медленнее, но более читаемо
      .map(article => ({
        ...article,
        pr_campaigns: article.pr_campaigns.map(i => i.id),
        tags: article.tags.map(i => i.id),
        post_id: parent.id,
        posts_count: 0
      }));

    const updatedParent = {
      ...parent,
      children: [parent?.children, ...updatedChildren]
        .filter(child => child)
        .flat(),
      pr_campaigns: parent.pr_campaigns.map(i => i.id),
      tags: parent.tags.map(i => i.id),
      posts_count: parent.posts_count + updatedChildren.length
    };

    checkboxManipulator([...updatedChildren, updatedParent])
      .then(() => {
        handleLoading({
          succeed: true,
          select: {
            needSelectParent: false,
            selected: null,
            allItemsForSelect: []
          }
        });
      })
      .catch(handleLoading({ error: true, progress: 0 }));

    handleCloseModalLoading(2500);
  };

  React.useEffect(() => {
    if (activeArticle?.posts_count > 0) {
      fetchGroupTags();
    }
  }, [activeArticle]);



  const moodRender = (selectedMood, onChange) => {
    const moods = MOODS.reverse();

    return (
      <Grid className={styles.Mood}>
        {moods.map(mood => (
          <div
            className={`${styles.Mood__Container} ${
              selectedMood === mood.type ? styles.Mood__Container_selected : ''
            }`}
            onClick={() => {
              setShowGroupPopover(prev => ({ ...prev, inMonitoring: false }));
              onChange(mood.type);
            }}
          >
            <mood.icon
              htmlColor={
                selectedMood === mood.type ? '#fff' : 'rgba(255,255,255, 0.5)'
              }
              fontSize="medium"
            />
            <span className={styles.Mood__Divider}>—</span>
            <div className={styles.Mood__Title}>{mood.label}</div>
          </div>
        ))}
      </Grid>
    );
  };

  const handleAddToFavorites = async node => {
    handleChangeLoadings({
      favorites: [...loadings.favorites, node.id]
    });

    await editOneArticle(
      node,
      {
        is_favorite: !node.is_favorite
      },
      {
        success: !node.is_favorite
          ? 'Добавлено в избранное'
          : 'Удалено из избранного',
        error: !node.is_favorite
          ? 'Ошибка при добавлении в избранное'
          : 'Ошибка при удалении новости из избранного'
      }
    );

    handleChangeLoadings({
      favorites: loadings.favorites.filter(({ id }) => id !== node.id)
    });
  };

  const handleSaveAudition = () => {
    editOneArticle(
      activeArticle,
      {
        audition: +actualAudition
      },
      {
        success: 'Аудитория новости успешно изменена',
        error: 'Произошла ошибка при изменении аудитории в новости'
      }
    );

    setAnchorAudiencePopover(null);
  };

  return (
    <Page event={event}>
      <Modal
        open={mergeData.showModal}
        onClose={
          (mergeData.succeed || mergeData.select.needSelectParent) &&
          handleCloseModalLoading
        }
        closeAfterTransition
        className={styles.Modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={mergeData.showModal}>
          <div className={styles.Modal__Container}>
            <div className={styles.Modal__Box}>
              {mergeData.select.needSelectParent ? (
                <div
                  style={{
                    opacity: mergeData.succeed ? 0.5 : 1
                  }}
                >
                  <div className={styles.Modal__Title}>Выберите родителя: </div>
                  <div className={styles.Modal__From}>
                    {mergeData.select.allItemsForSelect.map(article => (
                      <div
                        key={article.id}
                        className={styles.Modal__Item}
                        style={{
                          backgroundColor:
                            mergeData.select.selected.id === article.id &&
                            'rgba(0, 152, 95, 0.08)'
                        }}
                        onClick={() =>
                          handleLoading({
                            select: {
                              ...mergeData.select,
                              selected: article
                            }
                          })
                        }
                      >
                        {article?.children?.length && (
                          <img
                            alt="Папка"
                            className={styles.Modal__Img}
                            src={folder}
                          />
                        )}
                        <div className={styles.Modal__ArticleTitle}>
                          <span>{article.title}</span>
                          <strong>{` | Издание: ${article.source}`}</strong>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: 30 }}
                    onClick={() => {
                      handleLoading({
                        select: {
                          ...mergeData.select,
                          needSelectParent: false
                        },
                        from: mergeData.select.allItemsForSelect.filter(
                          article => article.id !== mergeData.select.selected.id
                        ),
                        to: mergeData.select.selected
                      });
                      checkboxMerge();
                    }}
                    disabled={!mergeData.select.selected}
                  >
                    Отправить новости
                  </Button>
                </div>
              ) : (
                <>
                  <div
                    className={styles.Modal__From}
                    style={{ opacity: mergeData.succeed ? 0.5 : 1 }}
                  >
                    <div className={styles.Modal__Title}>
                      Перемещаем эти статьи:{' '}
                    </div>
                    {mergeData.from.map(article => (
                      <div key={article.id} className={styles.Modal__Item}>
                        {article?.children?.length && (
                          <img
                            alt="Папка"
                            className={styles.Modal__Img}
                            src={folder}
                          />
                        )}
                        <div className={styles.Modal__ArticleTitle}>
                          {article.title}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.Modal__Loading}>
                    {mergeData.showModal && !mergeData.select.needSelectParent && (
                      <>
                        {mergeData.succeed && !mergeData.error && (
                          <div className={styles.Modal__Info}>
                            <div className={styles.Modal__InfoIcon}>
                              <CheckCircleOutlineIcon
                                color="primary"
                                fontSize="large"
                              />
                            </div>
                            <div className={styles.Modal__InfoText}>
                              Перемещение завершено!
                            </div>
                          </div>
                        )}
                        {!mergeData.succeed && !mergeData.error && (
                          <div className={styles.Modal__LinearProgress}>
                            <div className={styles.Modal__LinearProgressText}>
                              Перемещение...
                            </div>
                            <LinearProgress
                              variant="determinate"
                              value={mergeData.progress}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={styles.Modal__To}
                    style={{ opacity: mergeData.succeed ? 0.5 : 1 }}
                  >
                    <div className={styles.Modal__Title}>Перемещаем сюда: </div>
                    <div className={styles.Modal__Item}>
                      {mergeData.to?.children?.length && (
                        <img
                          alt="Папка"
                          className={styles.Modal__Img}
                          src={folder}
                        />
                      )}
                      <div className={styles.Modal__ArticleTitle}>
                        {mergeData.to?.title}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <Grid container direction="column">
        <div style={{ width: '95%', minHeight: '90vh', margin: '0 auto' }}>

          <HandbookTagsModal
            type={isOpenHandbookModal.type}
            postId={
              isOpenHandbookModal.type === HANDBOOK_TAG_TYPES.group
                ? buildDataGroup()
                : isOpenHandbookModal.type === HANDBOOK_TAG_TYPES.selected
                  ? checkedArticles
                  : (activeArticle && activeArticle.id)}
            isOpenModal={isOpenHandbookModal.show}
            onCloseHandler={() =>
              setIsOpenHandbookModal(prev => ({ ...prev, show: false }))
            }
            addTagsHandler={tags => {
              onSelectActiveArticle(activeArticle);

              if (isOpenHandbookModal.type === HANDBOOK_TAG_TYPES.selected) {
                fetchCheckedArticles(checkedArticles.map(i => i.id))
              }

              // if (isOpenHandbookModal.type === HANDBOOK_TAG_TYPES.group) {
              //   console.log('just skip');
              // }
            }}
            activeTags={
              isOpenHandbookModal.type === HANDBOOK_TAG_TYPES.group
                ? groupTags
                : isOpenHandbookModal.type === HANDBOOK_TAG_TYPES.selected
                ? selectedTags
                : [...activeArticleTags, ...activeArticlePRCampaign]
            }
          />
          <Grid lg={12} xl={12} container className={styles.EditorBlock}>
            <EditorManipulator
              filters={filters}
              setFilters={setFilters}
              refetchArticles={() => {
                refetchArticles({ totalRefetch: true });
              }}
              setEvent={(event) => setEvent(event)}
              sortArticlesPostsCount={preparingArticlesForTree}
              selectHandler={() => {
                const allArticles = [];

                articleList.forEach(item => {
                  if (item.children) {
                    item.children.forEach(i => allArticles.push(i));
                  }

                  allArticles.push(item);
                });

                setCheckedArticles(allArticles);
              }}
            />
            {(state?.prCampaignId || selectedPrCampaign.length > 0) && (
              <Grid lg={12} xl={12} className={styles.SelectedPRCBlock}>
                Выбранные PR-компании:
                {state?.prCampaignId && (
                  <div className={`${styles.Tag} ${styles.Active}`}>
                    <div>{state?.prCampaignName}</div>
                    <Link
                      to={{
                        pathname: (userInfo.role === 'pr_manager' || userInfo.role === 'editor') ? `/` : '/editor',
                        state: {}
                      }}
                      onClick={() => {
                        if (userInfo.role === 'analyst') {
                          window.location.reload();
                        }
                      }}
                    >
                      <div className={styles.Tag__Remove} />
                    </Link>
                  </div>
                )}

                {selectedPrCampaign.map(item => {
                  return (
                    <div key={item.name} className={`${styles.Tag} ${styles.Active}`}>
                      <div>{item.name}</div>
                      <div
                        className={styles.Tag__Remove}
                        onClick={() => {
                          const newArray = selectedPrCampaign.filter(i => i.id !== item.id);
                          setSelectedPrCampaign(newArray);
                        }}
                      />
                    </div>
                  )
                })}
              </Grid>
            )}
            <Grid lg={12} xl={12} className={styles.EditorBlockContent}>
              {loadings.global ? (
                <Loader />
              ) : (
                <SplitPane
                  split="vertical"
                  defaultSize="45%"
                  pane1Style={{ minWidth: '20%', maxWidth: '55%' }}
                  pane2Style={{ minWidth: '45%', maxWidth: '80%' }}
                >
                  <Grid lg={12} xl={12} classes={{ root: styles.FullHeight }}>
                    {totalCount > 0 ? (
                      <Grid
                        lg={12}
                        xl={12}
                        className={styles.ArticleListFilterTitle}
                      >
                        <span>
                          Найдено: {totalCount} {getDeclension(totalCount)}
                        </span>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid lg={12} xl={12} classes={{ root: styles.FullHeight }}>
                      <div
                        className={`${styles.LeftPanel} ${articleList.length <
                          1 && styles.LeftPanel_empty}`}
                      >
                        {articleList.length === 0 ? (
                          <div className={styles.LeftPanel__Empty}>
                            Статей не найдено
                          </div>
                        ) : (
                          <SortableTree
                            treeData={articleList}
                            onMoveNode={({ node, nextParentNode }) => {
                              if (userInfo.role !== 'pr_manager') {
                                dragNodeHandler(nextParentNode, node);
                              }
                            }}
                            onChange={updatedData => {
                              /*
                                  Это событие вызывается при изменении и ОТКРЫТИИ (кликом лкм) новостей.
                                  Это связано с добавлением поля expanded: true даже для одной новости (upd: и добавлением детей в поле children)
                                  Надеюсь это поможет кому-то в будущем...
                                */

                              setArticleList(prev => {
                                const updatedTree = [...updatedData];
                                const parentsBefore = prev.filter(item =>
                                  item.hasOwnProperty('children')
                                );
                                const parentsAfter = updatedData.filter(item =>
                                  item.hasOwnProperty('children')
                                );
                                const parent = _.differenceWith(
                                  parentsAfter,
                                  parentsBefore,
                                  _.isEqual
                                )[0];
                                const parentId = parent?.id;
                                const parentIndex = updatedTree.findIndex(
                                  article => article.id === parentId
                                );

                                if (parentIndex === -1) {
                                  return updatedData;
                                }

                                const children =
                                  parent?.children &&
                                  parent?.children
                                    .sort((a, b) => b.audition - a.audition)
                                    .map(child => ({
                                      ...child,
                                      post_id: parent.id
                                    }));

                                updatedTree[parentIndex] = {
                                  ...updatedTree[parentIndex],
                                  children
                                };

                                if (parent) {
                                  const additionalValue =
                                    parent?.children?.length >= 1 ? 1 : -1;
                                  updatedTree[parentIndex].posts_count =
                                    parent?.children?.length + additionalValue;
                                }

                                return updatedTree;
                              });
                            }}
                            theme={FileExplorerTheme}
                            maxDepth={2}
                            generateNodeProps={({ node }) => ({
                              onClick: e => {
                                if (node?.loader) {
                                  setPagesCount(prev => prev + 1);
                                } else {
                                  e.stopPropagation();
                                  onSelectActiveArticle(node);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                  });
                                }
                              },
                              indicators: [
                                node.processed && 'rgb(0, 168, 78)'
                              ].filter(indicator => typeof indicator === 'string'),

                              customCheckBoxHandler: () =>
                                handlerCheckboxArticleList(node),
                              checked: checkedArticles.find(
                                ({ id }) => id === node.id
                              ),
                              style: {
                                background:
                                  (activeArticle && activeArticle.id) ===
                                  node.id
                                    ? '#E9E9E9'
                                    : node.monitoring_date
                                    ? 'rgba(0, 152, 95, 0.08)'
                                    : 'transparent',
                                borderBottom: '1px solid #E5E5E5'
                              },
                              buttons: [
                                {
                                  content: loadings.favorites.includes(
                                    node.id
                                  ) ? (
                                    <CircularProgress color="primary" size={20} />
                                  ) : (
                                    <FavoritesIcon isActive={node.is_favorite} />
                                  ),
                                  handleClick: () => handleAddToFavorites(node)
                                }
                              ]
                            })}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      height: '100%',
                      overflow: 'hidden',
                      minHeight: 700
                    }}
                  >
                    {checkedArticles.length > 0 && (
                        <div className={styles.GroupControl}>
                          <div className={styles.GroupControl__Title}>
                            Управление выделенными новостями
                          </div>
                          <div className={styles.GroupControl__Content}>
                            {/*
                             {userInfo.role === 'editor' && (
                              <Button
                                onClick={() =>
                                  setShowCheckboxPopover(prev => ({
                                    ...prev,
                                    mood: true
                                  }))
                                }
                              >
                                Изменить тональность1
                              </Button>
                            )}
                            */}
                            {userInfo.role === 'editor' && (
                              <>
                                <Button
                                  onClick={checkboxProcessed}
                                  disabled={checkedArticles.every(
                                    article => article.processed
                                  )}
                                >
                                  Пометить как обработанные
                                </Button>
                                <Button
                                  onClick={checkboxDeleteFromMonitoring}
                                  disabled={checkedArticles.every(
                                    article => article.monitoring_date === null
                                  )}
                                >
                                  Удалить из мониторинга
                                </Button>
                              </>
                            )}
                            {showCheckboxPopover.inMonitoring && (
                              <MonitoringOptions
                                id="groupToMonitoring"
                                closeHandler={() =>
                                  setShowCheckboxPopover(prev => ({ ...prev, inMonitoring: false }))
                                }
                                submitHandler={(type, region, section) => {
                                  checkboxToMonitoring(
                                    type,
                                    region || regions[0].id,
                                    section || sections[0].id
                                  );
                                  setShowCheckboxPopover(prev => ({ ...prev, inMonitoring: false }));
                                }}
                                values={{
                                  inMonitoring: null,
                                  // поместить это в какую-то функцию, чтобы не было дублирования логики
                                  type: 3,
                                  section: checkedArticles.reduce(
                                    (reducer, { monitoring_section }) =>
                                      monitoring_section ===
                                      checkedArticles[0].monitoring_section
                                        ? monitoring_section
                                        : null,
                                    checkedArticles[0].monitoring_section
                                  ),
                                  region: checkedArticles.reduce(
                                    (reducer, item) =>
                                      item?.branch_id ===
                                      checkedArticles[0]?.branch_id
                                        ? item?.branch_id
                                        : null,
                                    checkedArticles[0]?.branch_id
                                  )
                                }}
                              />
                            )}
                            {userInfo.role === 'editor' && (
                              <Button
                                onClick={() =>
                                  setShowCheckboxPopover(prev => ({
                                    ...prev,
                                    inMonitoring: true
                                  }))
                                }
                              >
                                Поместить в мониторинг
                              </Button>
                            )}
                            {showCheckboxPopover.mood && (
                              <CustomPopover
                                id="groupChangeMood"
                                closeHandler={() =>
                                  setShowCheckboxPopover(prev => ({
                                    ...prev,
                                    mood: false
                                  }))
                                }
                                classes={{ root: styles.GroupControl__Mood }}
                              >
                                {moodRender(activeArticle?.mood, checkboxMood)}
                              </CustomPopover>
                            )}
                            {userInfo.role === 'editor' &&
                              checkedArticles.length > 1 && (
                                <Button onClick={callMerge}>
                                  Объединить папки и элементы
                                </Button>
                              )}
                            {userInfo.role === 'editor' &&
                              (checkedArticles.some(
                                ({ is_favorite }) => !is_favorite
                              ) ? (
                                <Button onClick={checkboxAddToFavorite}>
                                  Добавить в избранное
                                </Button>
                              ) : (
                                <Button onClick={checkboxDeleteFromFavorite}>
                                  Удалить из избранного
                                </Button>
                              ))}
                            <Button onClick={() => setCheckedArticles([])}>
                              Снять выделение
                            </Button>
                          </div>


                          <TagsForSelectedArticles
                            tags={selectedTags}
                            setIsOpenHandbookModal={(params) => setIsOpenHandbookModal(params)}
                            removeSelectedTag={(tag) => removeSelectedTag(tag)}
                            selectedPrCampaign={selectedPrCampaign}
                            setSelectedPrCampaign={(array) => setSelectedPrCampaign(array)}
                          />
                        </div>
                      )}
                    {activeArticle?.posts_count > 0 &&
                      articleList.length && (
                        <div className={styles.GroupControl}>
                          <div className={styles.GroupControl__Title}>
                            Управление группой новостей
                          </div>
                          <div className={styles.GroupControl__Content}>
                            {showGroupPopover.inMonitoring && (
                              <MonitoringOptions
                                id="groupToMonitoring"
                                closeHandler={() =>
                                  setShowGroupPopover(prev => ({...prev, inMonitoring: false}))
                                }
                                submitHandler={(type, region, section) => {
                                  handleGroupToMonitoring(
                                    type,
                                    region || regions[0].id,
                                    section || sections[0].id
                                  );
                                  setShowGroupPopover(prev => ({ ...prev, inMonitoring: false }));
                                }}
                                values={{
                                  inMonitoring:
                                    activeArticle.monitoring_date !== null,
                                  type: activeArticle.monitoring_type,
                                  region: activeArticle?.branch_id,
                                  section: activeArticle.monitoring_section
                                }}
                              />
                            )}
                            {/*
                            {showGroupPopover.mood && (
                              <CustomPopover
                                id="groupChangeMood"
                                closeHandler={() =>
                                  setShowGroupPopover(prev => ({
                                    ...prev,
                                    mood: false
                                  }))
                                }
                                classes={{ root: styles.GroupControl__Mood }}
                              >
                                {moodRender(activeArticle?.mood, handleGroupMood)}
                              </CustomPopover>
                            )}
                            <Button
                              onClick={() =>
                                setShowGroupPopover(prev => ({
                                  ...prev,
                                  mood: true
                                }))
                              }
                            >
                              Изменить тональность2
                            </Button>
                            */}
                            {userInfo.role === 'editor' && (
                              <Button
                                onClick={handleGroupProcessed}
                                disabled={
                                  activeArticle?.children &&
                                  [
                                    ...activeArticle?.children,
                                    activeArticle
                                  ].every(child => child.processed)
                                }
                              >
                                Пометить как обработанные
                              </Button>
                            )}
                            {userInfo.role === 'editor' && (
                              <>
                                <Button
                                  onClick={() =>
                                    setShowGroupPopover(prev => ({
                                      ...prev,
                                      inMonitoring: true
                                    }))
                                  }
                                >
                                  {activeArticle.monitoring_date === null
                                    ? 'Поместить в мониторинг'
                                    : 'Изменить выбранный мониторинг'}
                                </Button>
                                <Button
                                  onClick={handleGroupDeleteFromMonitoring}
                                  disabled={
                                    activeArticle?.children &&
                                    [
                                      ...activeArticle?.children,
                                      activeArticle
                                    ].every(
                                      child => child.monitoring_date === null
                                    )
                                  }
                                >
                                  Удалить из мониторинга
                                </Button>
                              </>
                            )}
                          </div>
                          <Grid lg={12} xl={12}>
                            <div
                              className={styles.Tags}
                              style={{ margin: '10px 0 0' }}
                            >
                              {groupTags.flat().length > 0 &&
                                groupTags.map(tag => (
                                  <div className={styles.Tag}>
                                    <div>{tag.title}</div>
                                    <div
                                      className={styles.Tag__Remove}
                                      onClick={() => removeGroupTag(tag)}
                                    />
                                  </div>
                                ))}
                              <div
                                className={`${styles.Tag} ${styles.Tag_add}`}
                                onClick={() => {
                                  setIsOpenHandbookModal({
                                    show: true,
                                    type: HANDBOOK_TAG_TYPES.group
                                  });
                                }}
                              >
                                <div className={styles.Tag__Add}>+</div>
                                <div>Добавить теги ко всем новостям</div>
                              </div>
                            </div>
                          </Grid>
                        </div>
                      )}
                    {isPendingArticleEdit ? (
                      <Loader text="Сохранение изменений. Подождите..." />
                    ) : (
                      <>
                        {!!articleList.length && activeArticle && (
                          <Grid lg={12} xl={12} className={styles.Article}>
                            <Grid
                              container
                              alignItems="center"
                              justify="space-between"
                              className={styles.ArticleActionPanel}
                            >
                              <Grid
                                lg={6}
                                xl={6}
                                container
                                alignItems="center"
                                justify="flex-end"
                              >
                                <Grid className={styles.ArticleStatusBlock}>
                                  {activeArticle && activeArticle.processed ? (
                                    <AlarmOnIcon htmlColor="#fff" />
                                  ) : (
                                    <AccessTimeIcon htmlColor="#fff" />
                                  )}
                                  <span className={styles.ArticleStatusLabel}>
                                    {activeArticle && activeArticle.processed
                                      ? 'Обработано'
                                      : 'Не обработано'}
                                  </span>
                                </Grid>
                                <Grid className={styles.ArticleStatusBlock}>
                                  {activeArticle &&
                                  activeArticle.monitoring_date ? (
                                    <PhonelinkIcon htmlColor="#fff" />
                                  ) : (
                                    <PhonelinkOffIcon htmlColor="#fff" />
                                  )}
                                  <span className={styles.ArticleStatusLabel}>
                                    {activeArticle &&
                                    activeArticle.monitoring_date
                                      ? `В мониторинге`
                                      : 'Не в мониторинге'}
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid>
                                {activeArticle?.post_id &&
                                  userInfo.role !== 'pr_manager' && (
                                    <Tooltip
                                      title="Сделать новость родительской"
                                      placement="bottom"
                                      classes={{ tooltip: styles.Tooltip }}
                                    >
                                      <StyledIconButton
                                        onClick={() => swapGroupParent()}
                                      >
                                        <SwapVertIcon htmlColor="#fff" />
                                      </StyledIconButton>
                                    </Tooltip>
                                  )}
                                <Tooltip
                                  title="Отредактировать новость"
                                  placement="bottom"
                                  classes={{ tooltip: styles.Tooltip }}
                                >
                                  <StyledIconButton
                                    classes={{
                                      root: editMode && styles.Active
                                    }}
                                    styles={{ backgroundColor: 'stategray' }}
                                  >
                                    <CreateIcon
                                      htmlColor="#fff"
                                      onClick={() => {
                                        editMode
                                          ? setShowAlert(true)
                                          : setEditMode(true);
                                      }}
                                    />
                                  </StyledIconButton>
                                </Tooltip>
                                {/* {userInfo.role !== 'pr_manager' && (
                                  <Tooltip
                                    title="Изменить тональность"
                                    placement="bottom"
                                    classes={{ tooltip: styles.Tooltip }}
                                  >
                                    <StyledIconButton
                                      onClick={() =>
                                        setShowMoodTooltip(prev => !prev)
                                      }
                                    >
                                      {activeArticle?.mood === 'negative' && (
                                        <SentimentVeryDissatisfiedIcon htmlColor="#fff" />
                                      )}
                                      {activeArticle?.mood === 'neutral' && (
                                        <SentimentSatisfiedIcon htmlColor="#fff" />
                                      )}
                                      {activeArticle?.mood === 'positive' && (
                                        <SentimentVerySatisfiedIcon htmlColor="#fff" />
                                      )}
                                    </StyledIconButton>
                                  </Tooltip>
                                )} */}
                                {/*
                                {showMoodTooltip && (
                                  <CustomPopover
                                    id="soloMood"
                                    closeHandler={() =>
                                      setShowCheckboxPopover(prev => ({
                                        ...prev,
                                        mood: false
                                      }))
                                    }
                                    classes={{
                                      root: styles.GroupControl__Mood
                                    }}
                                  >
                                    {moodRender(activeArticle?.mood, mood => {
                                      editMood(mood);
                                      setShowMoodTooltip(prev => !prev);
                                    })}
                                  </CustomPopover>
                                )}
                                */}
                                <Tooltip
                                  title="Добавить теги"
                                  placement="bottom"
                                  classes={{ tooltip: styles.Tooltip }}
                                >
                                  <StyledIconButton
                                    onClick={() =>
                                      setIsOpenHandbookModal(() => ({
                                        show: true,
                                        type: HANDBOOK_TAG_TYPES.item
                                      }))
                                    }
                                  >
                                    <TagsIcon htmlColor="#fff" />
                                  </StyledIconButton>
                                </Tooltip>
                                {userInfo.role === 'editor' &&
                                  !activeArticle.processed && (
                                    <Tooltip
                                      title="Установить статус 'Обработано'"
                                      placement="bottom"
                                      classes={{ tooltip: styles.Tooltip }}
                                    >
                                      <StyledIconButton
                                        onClick={sendArticleInProcessed}
                                        disabled={activeArticle.processed}
                                      >
                                        <CheckCircleOutlineOutlinedIcon htmlColor="#fff" />
                                      </StyledIconButton>
                                    </Tooltip>
                                  )}
                                <Dialog
                                  open={showAlert}
                                  keepMounted
                                  onClose={() => setShowAlert(false)}
                                  aria-labelledby="alert-dialog-slide-title"
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogTitle id="alert-dialog-slide-title">
                                    Сохранить изменения в новости?
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        handleEditCancel();
                                        setShowAlert(false);
                                      }}
                                    >
                                      Не сохранять
                                    </Button>
                                    <Button
                                      onClick={handleEditSave}
                                      color="access"
                                      style={{ background: '#00B956', color: '#ffffff' }}
                                    >
                                      Сохранить
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                                {userInfo.role === 'editor' && (
                                  <Tooltip
                                    title="Настройки аудитории"
                                    placement="bottom"
                                    classes={{ tooltip: styles.Tooltip }}
                                  >
                                    <StyledIconButton
                                      onClick={e =>
                                        setAnchorAudiencePopover(
                                          e.currentTarget
                                        )
                                      }
                                    >
                                      <SettingsIcon htmlColor="#fff" />
                                    </StyledIconButton>
                                  </Tooltip>
                                )}
                                <Popover
                                  open={!!anchorAudiencePopover}
                                  anchorEl={anchorAudiencePopover}
                                  onClose={() => {
                                    setAnchorAudiencePopover(null);
                                    setActualAudition(activeArticle?.audition);
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                  }}
                                >
                                  <div className={styles.AudiencePopover}>
                                    <TextField
                                      label="Аудитория от скана"
                                      variant="filled"
                                      value={activeArticle?.original_audition}
                                      classes={{
                                        root: styles.AudiencePopover__TextField
                                      }}
                                      disabled
                                    />
                                    <TextField
                                      label="Актуальная аудитория"
                                      variant="filled"
                                      value={actualAudition}
                                      onChange={e =>
                                        setActualAudition(e.target.value)
                                      }
                                      classes={{
                                        root: styles.AudiencePopover__TextField
                                      }}
                                    />
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleSaveAudition}
                                      classes={{
                                        root: styles.AudiencePopover__Submit
                                      }}
                                    >
                                      Сохранить
                                    </Button>
                                  </div>
                                </Popover>
                                {userInfo.role === 'editor' &&
                                  activeArticle.monitoring_date !== null && (
                                    <Tooltip
                                      title="Удалить из мониторинга"
                                      placement="bottom"
                                      classes={{ tooltip: styles.Tooltip }}
                                    >
                                      <StyledIconButton
                                        style={{ position: 'relative' }}
                                        onClick={handleDeleteFromMonitoring}
                                      >
                                        <div
                                          style={{
                                            transform: 'rotate(180deg)',
                                            marginTop: '-6px'
                                          }}
                                        >
                                          <ArrowForwardOutlinedIcon htmlColor="#fff" />
                                        </div>
                                      </StyledIconButton>
                                    </Tooltip>
                                  )}
                                {userInfo.role === 'editor' &&
                                  (!activeArticle.post_id ||
                                    articleList.find(
                                      ({ id }) => id === activeArticle.post_id
                                    )?.monitoring_date) && (
                                    <StyledIconButton
                                      style={{ position: 'relative' }}
                                    >
                                      <Tooltip
                                        title="Добавить в мониторинг"
                                        placement="bottom"
                                        classes={{ tooltip: styles.Tooltip }}
                                      >
                                        <ArrowForwardOutlinedIcon
                                          htmlColor="#fff"
                                          onClick={() =>
                                            setIsOpenOptionsPopover(true)
                                          }
                                        />
                                      </Tooltip>
                                      {isOpenOptionsPopover && (
                                        <MonitoringOptions
                                          id="monitoringOptions"
                                          closeHandler={() =>
                                            setIsOpenOptionsPopover(false)
                                          }
                                          submitHandler={(
                                            type,
                                            region,
                                            section
                                          ) => {
                                            sendToMonitoringHandler(
                                              type,
                                              region || regions[0].id,
                                              section || sections[0].id
                                            );
                                            setIsOpenOptionsPopover(false);
                                          }}
                                          values={{
                                            type: [2, 3].includes(
                                              activeArticle.monitoring_type
                                            )
                                              ? activeArticle.monitoring_type
                                              : 3,
                                            section:
                                              activeArticle.monitoring_section,
                                            region: activeArticle?.branch_id,
                                            inMonitoring:
                                              activeArticle.monitoring_date
                                          }}
                                        />
                                      )}
                                    </StyledIconButton>
                                  )}
                              </Grid>
                            </Grid>
                            {activeArticle &&
                              activeArticle.monitoring_date !== null && (
                                <Grid
                                  container
                                  alignItems="center"
                                  justify="space-between"
                                  className={`${styles.ArticleActionPanel} ${styles.ArticleActionPanel_monitoring}`}
                                >
                                  Новость помещена в мониторинг: &nbsp;
                                  {types.find(({ id }) => id === activeArticle.monitoring_type)?.name}
                                  {activeArticle.monitoring_type === 2 && (
                                    <>
                                      &nbsp;({regions.find(({ id }) => id === activeArticle.monitoring_region)?.name})
                                    </>
                                  )}
                                  <span>&nbsp;—&nbsp;</span>
                                  {
                                    sections.find(
                                      ({ key }) =>
                                        key === activeArticle.monitoring_section
                                    )?.name
                                  }
                                </Grid>
                              )}
                            <TagsForActiveArticle
                              activeArticle={activeArticle}
                              tags={[...activeArticleTags, ...activeArticlePRCampaign] || []}
                              removeTagHandler={(tag) => removeTag(tag)}
                              selectedPrCampaign={selectedPrCampaign}
                              setSelectedPrCampaign={(array) => setSelectedPrCampaign(array)}
                            />
                            <Grid lg={12} xl={12}>
                              {editMode ? (
                                <EditModeForm
                                  articleData={activeArticle}
                                  handleCancel={() => setEditMode(false)}
                                  handleChange={setEditData}
                                  handleSave={handleEditSave}
                                />
                              ) : (
                                <>
                                  <div style={{ margin: '10px -7px' }}>
                                    {userInfo.role === 'editor' && (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            classes={{
                                              root: styles.Header__Checkbox
                                            }}
                                            checked={hideImagesInArticle}
                                            onChange={({
                                              target: { checked }
                                            }) =>
                                              setHideImagesInArticle(
                                                () => checked
                                              )
                                            }
                                          />
                                        }
                                        label="Скрыть изображения в статье"
                                      />
                                    )}
                                  </div>
                                  <ArticleContent
                                    article={activeArticle}
                                    settings={{
                                      hideImages: hideImagesInArticle
                                    }}
                                  />
                                </>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </div>
                </SplitPane>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Page>
  );
};

// Exports
export default EditorModule;
