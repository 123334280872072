import React from 'react';

import Chart, {
  CommonSeriesSettings,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
  Title
} from 'devextreme-react/chart';
import { divideGraphsValue } from '../../../../../utils/methods';

const CombineRowBarChart = ({ dataSource, dividers = {}, series, title, withDefaultTooltip = true }: any) => {
  const formattedDataSource = dataSource.map(items => ({
    ...items,
    ...Object.fromEntries(Object.entries(dividers).map(([field, divider]) => [field, items[field] / divider]))
  }));

  return (
    <Chart
      title={title}
      dataSource={formattedDataSource}
    >
      <ValueAxis>
        <Title text="Публикации, единицы" />
      </ValueAxis>
      <CommonSeriesSettings argumentField="date" type="stackedBar" />
      {series.map(({ name, valueField, type, color, stack, showInLegend}) => (
        <Series
          key={name}
          name={name}
          valueField={valueField}
          type={type}
          color={color}
          stack={stack}
          showInLegend={showInLegend}
        />
      ))}
      <Tooltip
        enabled
        shared
        customizeTooltip={(pointInfo) => customizeTooltip(pointInfo, withDefaultTooltip, series, dividers)}
      />
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
      />
    </Chart>
  );
};

// eslint-disable-next-line max-params
function customizeTooltip(pointInfo, withDefaultTooltip, series, dividers) {
  if (withDefaultTooltip) {
    const firstPointValue = Number(pointInfo.points[0]?.valueText);
    const secondPointValue = Number(pointInfo.points[1]?.valueText);

    const firstPointDivider = dividers[series.find(({ name }) => name === pointInfo.points[0]?.seriesName)?.valueField];
    const secondPointDivider = dividers[series.find(({ name }) => name === pointInfo.points[1]?.seriesName)?.valueField];

    return {
      html: `
        <div>
          <div class="tooltip-header">
            ${pointInfo.argumentText}
          </div>
          <div class="tooltip-body">
            <div class="series-name">
              <span class='top-series-name'>
                ${pointInfo.points[0]?.seriesName}
              </span>
              : ${divideGraphsValue(firstPointDivider ? firstPointValue * firstPointDivider : firstPointValue)}
            </div>
            <div class="series-name">
              <span class='bottom-series-name'>
                ${pointInfo.points[1]?.seriesName}
              </span>
                : ${divideGraphsValue(secondPointDivider ? secondPointValue * secondPointDivider : secondPointValue)}
              </div>
            </div>
          </div>
      `,
    };
  }

  const ID_TO_NAME = {
    'Билайн': 'beeline',
    'YOTA': 'yota',
    'МегаФон': 'megafon',
    'МТС': 'mts',
    'Теле2': 'tele2',
    'Общие': 'big4',
  };
  const mappedId = ID_TO_NAME[pointInfo.seriesName];
  const fieldOfCount = `${mappedId}_count`;
  const date = pointInfo.argumentText;

  return {
    html: `
      <div>
        <div class="tooltip-header">
          <div style='font-weight: bold; margin-bottom: 10px'>
            ${date}
          </div>
        </div>
        <div class="tooltip-body">
          <div style='margin: 10px 0'>Всего упоминаний: ${pointInfo.point.data[fieldOfCount]}</div>
        </div>
      </div>`
  };
}

export default CombineRowBarChart;
